.dashboard {
     $dropdown-line-height: rem(16);
     .dropdown {
          &-toggle {
               // Dropdown
               // Dropdown button
               $dropdown-btn-min-width: rem(210);
               $dropdown-btn-font-weight: 400;

               // small
               $dropdown-btn-font-size-sm: rem(12);
               $doprdown-btn-line-height-sm: rem(16);
               $dropdown-btn-padding-top-sm: rem(6);
               $dropdown-btn-padding-right-sm: rem(8);
               $dropdown-btn-padding-bottom-sm: rem(6);
               $dropdown-btn-padding-left-sm: rem(12);
               // end small

               // large
               $dropdown-btn-font-size-lg: rem(14);
               $doprdown-btn-line-height-lg: rem(20);
               $dropdown-btn-padding-top-lg: rem(7.5);
               $dropdown-btn-padding-right-lg: rem(8);
               $dropdown-btn-padding-bottom-lg: rem(7.5);
               $dropdown-btn-padding-left-lg: rem(12);
               // end large

               $dropdown-btn-border-width: rem(1);
               $dropdown-btn-border-color: $border-colors;
               $dropdown-btn-border-radius: rem(4);
               $dropdown-btn-bg-color: $white;

               $doprdown-btn-font-family: '';
               $dropdown-color: #1F2937 !default;

               $dropdown-bg: $white;

               $dropdown-border-color: $border-colors;
               $dropdown-border-radius: rem(4);
               $dropdown-border-width: var(--cui-border-width) !default;
               $dropdown-inner-border-radius: 0; // stylelint-disable-line function-disallowed-list
               $dropdown-divider-bg: $dropdown-border-color !default;
               $dropdown-divider-margin-y: 0;
               $dropdown-box-shadow: var(--cui-box-shadow) !default;

               $dropdown-link-color: #1F2937 !default;
               $dropdown-link-hover-color: $dropdown-link-color !default;
               $dropdown-link-hover-bg: $white;

               $dropdown-link-active-color: $body-colors;
               $dropdown-link-active-bg: $uncolored;

               $dropdown-link-disabled-color: var(--cui-tertiary-color) !default;

               $dropdown-item-padding-y: rem(6);
               $dropdown-item-padding-x: rem(12);

               $dropdown-header-color: $gray-600 !default;
               $dropdown-font-size: rem(12);
               // Dropdown list
               $dropdown-min-width: 100%;
               $dropdown-padding-x: rem(0);
               $dropdown-padding-y: rem(0);
               // sm
               --#{$prefix}dropdown-btn-font-size-sm: #{$dropdown-btn-font-size-sm};
               --#{$prefix}doprdown-btn-line-height-sm: #{$doprdown-btn-line-height-sm};
               --#{$prefix}dropdown-btn-padding-top-sm: #{$dropdown-btn-padding-top-sm};
               --#{$prefix}dropdown-btn-padding-right-sm: #{$dropdown-btn-padding-right-sm};
               --#{$prefix}dropdown-btn-padding-left-sm: #{$dropdown-btn-padding-left-sm};
               --#{$prefix}dropdown-btn-padding-bottom-sm: #{$dropdown-btn-padding-bottom-sm};
               // lg
               --#{$prefix}dropdown-btn-font-size-lg: #{$dropdown-btn-font-size-lg};
               --#{$prefix}doprdown-btn-line-height-lg: #{$doprdown-btn-line-height-lg};
               --#{$prefix}dropdown-btn-padding-top-lg: #{$dropdown-btn-padding-top-lg};
               --#{$prefix}dropdown-btn-padding-right-lg: #{$dropdown-btn-padding-right-lg};
               --#{$prefix}dropdown-btn-padding-left-lg: #{$dropdown-btn-padding-left-lg};
               --#{$prefix}dropdown-btn-padding-bottom-lg: #{$dropdown-btn-padding-bottom-lg};
               // general
               --#{$prefix}dropdown-btn-min-width: #{$dropdown-btn-min-width};
               --#{$prefix}dropdown-btn-font-weight: #{$dropdown-btn-font-weight};
               --#{$prefix}dropdown-btn-border-width: #{$dropdown-btn-border-width};
               --#{$prefix}dropdown-btn-border-color: #{$dropdown-btn-border-color};
               --#{$prefix}dropdown-btn-bg-color: #{$dropdown-btn-bg-color};
               --#{$prefix}btn-active-bg: #{$dropdown-btn-bg-color};
               --#{$prefix}btn-active-border-color: #{$dropdown-btn-border-color};
               --#{$prefix}btn-focus-bg: #{$dropdown-btn-bg-color};

               --#{$prefix}btn-border-radius: #{$dropdown-btn-border-radius};
               // --#{$prefix}btn-font-family: #{$dropdown-btn-padding-bottom};
               // Large
               $dropdown-padding-y-lg: rem(9.5);

               // min-width: var(--#{$prefix}dropdown-btn-min-width);
               font-weight: var(--#{$prefix}dropdown-btn-font-weight);
               border: var(--#{$prefix}dropdown-btn-border-width) solid var(--#{$prefix}dropdown-btn-border-color);
               background-color: var(--#{$prefix}dropdown-btn-bg-color);
               display: flex;
               width: 100%;
               justify-content: space-between;
               align-items: center;
               text-align: left;

               &.btn-sm {
                    font-size: var(--#{$prefix}dropdown-btn-font-size-sm);
                    line-height: var(--#{$prefix}doprdown-btn-line-height-sm);
                    padding-top: var(--#{$prefix}dropdown-btn-padding-top-sm);
                    padding-right: var(--#{$prefix}dropdown-btn-padding-right-sm);
                    padding-bottom: var(--#{$prefix}dropdown-btn-padding-bottom-sm);
                    padding-left: var(--#{$prefix}dropdown-btn-padding-left-sm);
               }
               &.btn-lg {
                    font-size: var(--#{$prefix}dropdown-btn-font-size-lg);
                    line-height: var(--#{$prefix}doprdown-btn-line-height-lg);
                    padding-top: var(--#{$prefix}dropdown-btn-padding-top-lg);
                    padding-right: var(--#{$prefix}dropdown-btn-padding-right-lg);
                    padding-bottom: var(--#{$prefix}dropdown-btn-padding-bottom-lg);
                    padding-left: var(--#{$prefix}dropdown-btn-padding-left-lg);
                    & + .dropdown-menu {
                         --#{$prefix}dropdown-item-padding-y: #{$dropdown-padding-y-lg};
                    }
               }

               &:hover,
               &:active,
               &:focus {
                    border: var(--#{$prefix}dropdown-btn-border-width) solid var(--#{$prefix}dropdown-btn-border-color);
                    background-color: var(--#{$prefix}dropdown-btn-bg-color);
               }

               &:after:not(.btn-undefined) {
                    content: '';
                    background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M11.9393%204.93933L7.99997%208.87871L4.06056%204.93933L3%205.99999L7.99997%2011L13%205.99999L11.9393%204.93933Z%22%20fill%3D%22%2318222B%22%2F%3E%3C%2Fsvg%3E');
                    width: 16px;
                    height: 16px;
                    border: none;
                    margin-left: 8px;
                    transform: rotate(0deg);
                    transition: all 1.5s ease-in-out !important;
                    // animation: rotateend 0.3s forwards;
               }

               &:after {
                    color: black;
               }

               &.show {
                    &:after {
                         transform: rotate(0deg);
                         // animation: rotatestart 0.3s forwards;
                    }
               }
          }
          &-item {
               --#{$prefix}dropdown-line-height: #{$dropdown-line-height};

               line-height: var(--#{$prefix}dropdown-line-height);
               white-space: normal;
               &:first-child {
                    border-bottom-left-radius: 0px;
                    border-bottom-right-radius: 0px;
               }
               input[type='checkbox'] {
                    margin-right: 8px;
                    border-radius: 4px;
               }
          }
          &-menu {
               &.show.dashboard {
                    top: 8px !important;
               }
               &.dropdow-date-box-period {
                    min-width: 160px;
                    border: 1px solid #cfd4d8;
                    border-top-right-radius: 0;
                    svg {
                         &.arrow-date-range-period {
                              &.arrow-setting {
                                   position: absolute;
                                   right: -10px;
                                   width: 71px;
                                   height: 10px;
                                   top: -10px;
                              }
                         }
                    }
                    li {
                         &:nth-child(2) {
                              a {
                                   border-top-left-radius: 0.25rem;
                                   border-top-right-radius: 0;
                                   border-bottom-right-radius: 0;
                                   border-bottom-left-radius: 0;
                              }
                         }
                         &:last-child {
                              a {
                                   border-top-left-radius: 0;
                                   border-top-right-radius: 0;
                                   border-bottom-right-radius: 0.25rem;
                                   border-bottom-left-radius: 0.25rem;
                              }
                         }
                    }
                    li:not(:last-child) {
                         border-bottom: none;
                    }
                    a {
                         &:hover {
                              background: #20a8d8;
                              color: $white;
                         }
                    }
               }
               // li:not(:last-child) {
               //      border-bottom: 1px solid var(--#{$prefix}dropdown-border-color);
               // }
          }
          &-select {
               max-width: 256px;
               padding: 0;
               .variable-filter,
               .type-variable {
                    input {
                         border-radius: 0;
                         border-top: unset;
                         &:not([type='checkbox']) {
                              background-color: inherit;
                              font-weight: 500;
                         }
                    }
                    &::marker {
                         display: none;
                    }
               }
          }
     }

     @keyframes rotatestart {
          from {
               transform: rotate(0deg);
          }
          to {
               transform: rotate(180deg);
          }
     }
     @keyframes rotateend {
          from {
               transform: rotate(180deg);
          }
          to {
               transform: rotate(0deg);
          }
     }
}
