.tippy {
     &-box.custom-tooltip-hover {
          position: relative !important;
          // min-width: 192px;
          width: max-content;
          min-height: fit-content;
          background-color: transparent !important;
          
          &.tooltip-segment {
               svg {
                    path:first-child {
                         stroke: none !important;
                    }
               }
          }

          .tippy-content {
               border-radius: 8px;
               padding: 0px !important;
               // svg {
               //      path {
               //           fill: white !important;
               //      }
               // }
               .badge {
                    &-tooltip {
                         &-wrapper {
                              display: flex;
                              gap: 8px;
                              color: #fff;
                              padding: 8px;
                              font-size: 12px;
                              line-height: 16px;
                              flex-direction: column;
                         }
                         &-head {
                              font-weight: 700;
                              gap: 4px;
                              .icon {
                                   flex-shrink: 0;
                              }
                              svg {
                                   path:first-child {
                                        stroke: #fff;
                                   }
                              }
                              span {
                                   text-wrap: auto;
                                   text-align: left;
                                   &.hasIconClose {
                                        width: 78%;
                                   }
                              }
                         }
                         &-des {
                              font-weight: 400;
                              text-wrap: auto;
                              text-align: left;
                         }
                         &-icon-clear {
                              top: 6px;
                              right: 8px;
                              cursor: pointer;
                         }
                    }
               }
          }
          .tippy-svg-arrow {
               width: 9px;
               height: 9px;
               transform: unset !important;
               line-height: 1.4;
               font-size: 14px;
          }
          &.top-start {
               .tippy-content {
                    border-radius: 8px 8px 8px 0;
               }
               .tippy-svg-arrow {
                    bottom: 1px !important;
                    left: 7px !important;
               }
          }
          &.top-end {
               .tippy-content {
                    border-radius: 8px 8px 0 8px;
               }
               .tippy-svg-arrow {
                    right: 7px !important;
                    bottom: 1px !important;
                    left: unset !important;
               }
          }
          &.bottom-end {
               .tippy-content {
                    border-radius: 8px 0 8px 8px;
               }
               .tippy-svg-arrow {
                    top: -10px !important;
                    right: 7px !important;
                    left: unset !important;
               }
          }
          &.bottom-start {
               .tippy-content {
                    border-radius: 0 8px 8px 8px;
               }
               .tippy-svg-arrow {
                    top: -10px !important;
                    left: 7px !important;
               }
          }
          &.right-start {
               .tippy-content {
                    border-radius: 0 8px 8px 8px;
               }
               .tippy-svg-arrow {
                    top: -1px !important;
                    left: 0 !important;
               }
          }
          &.right-end {
               .tippy-content {
                    border-radius: 8px 8px 8px 0;
               }
               .tippy-svg-arrow {
                    top: unset !important;
                    bottom: 9px !important;
                    left: -1px !important;
               }
          }
          &.left-end {
               .tippy-content {
                    border-radius: 8px 8px 0 8px;
               }
               .tippy-svg-arrow {
                    top: unset !important;
                    right: -3px !important;
                    bottom: 9px !important;
               }
          }
          &.left-start {
               .tippy-content {
                    border-radius: 8px 0 8px 8px;
               }
               .tippy-svg-arrow {
                    top: -3px !important;
                    right: -3px !important;
               }
          }
          &.top {
               .tippy-svg-arrow {
                    bottom: 2px !important;
                    left: 50% !important;
               }
          }
          &.bottom {
               .tippy-svg-arrow {
                    top: -9px !important;
                    left: 50% !important;
               }
          }
          &.right {
               .tippy-svg-arrow {
                    top: unset !important;
                    bottom: 50% !important;
                    left: -3px !important;
               }
          }
          &.left {
               .tippy-svg-arrow {
                    top: unset !important;
                    right: -3px !important;
                    bottom: 50% !important;
               }
          }
          &.tooltipdata {
               .tippy-content {
                    border-radius: 8px;
               }
          }
     }
}
