// scss-docs-start btn-variant-loops
@each $state, $variant in $button-variants {
     .btn-#{$state} {
          @include button-variant($variant);
     }
}

@each $state, $variant in $button-outline-ghost-variants {
     .btn-outline-#{$state} {
          @include button-outline-variant($variant);
     }
}

@each $state, $variant in $button-outline-ghost-variants {
     .btn-ghost-#{$state} {
          @include button-ghost-variant($variant);
     }
}
// scss-docs-end btn-variant-loops

.btn {
     --cui-btn-active-color: #63c2de;
     text-transform: uppercase;
     position: relative;
     border-radius: 3.25rem;

     &.btn-navigation-step {
          &:active {
               color: #73818f;
               background: unset;
          }
     }
     &.btn-back {
          &:active {
               color: #73818f;
               background: unset;
          }
     }
     &.active {
          background: unset;
          &:active {
               background: unset;
          }
     }
     &:first-child {
          &:active {
               background: unset;
          }
     }

     &:hover,
     &:focus {
          box-shadow: none !important;
     }
     &-light {
          --#{$prefix}btn-bg: #f0f3f5;
     }
     &-success {
          --#{$prefix}btn-bg: #4dbd74;
          --#{$prefix}btn-border-color: #4dbd74;
     }
     &-primary.disabled {
          color: $white;
          background-color: $primary;
          opacity: 0.5;
     }
     &[class*='btn-outline-'] {
          border-width: 1px;
     }
     &-default {
          label {
               text-transform: capitalize;
               padding: 4px 9px;
               max-height: 30px;
               font-weight: 400;
               background-color: $white;
          }
     }
}

.btn-lg,
.btn-group-lg > .btn {
     --cui-btn-border-radius: #{$btn-border-radius};

     --#{$prefix}btn-padding-top: #{$btn-padding-top-lg};
     --#{$prefix}btn-padding-bottom: #{$btn-padding-bottom-lg};
     --#{$prefix}btn-padding-left: #{$btn-padding-left-lg};
     --#{$prefix}btn-padding-right: #{$btn-padding-right-lg};
     --cui-btn-font-size: #{$btn-font-size};
}

.btn-group.btn-default {
     label {
          margin-bottom: 0px;
     }
}

.btn-brand {
     font-size: 14px;
     font-weight: 500;
     padding: 0.4em 0.8em;
     background-color: #20a8d8;
     border-color: #20a8d8;
     &.disabled {
          color: #fff;
          cursor: not-allowed;
     }
     border-radius: 20px;
}
.btn-datalayer {
     font-size: 14px;
     background-color: #fff;
     border-color: #20a8d8;
     border: solid 1px !important;
     border-radius: 20px;
     color: #63c2de;
     font-family: Roboto;
     font-size: 12px;
     font-weight: 700;
     line-height: 14px;
     text-align: center;
     text-transform: uppercase;
     letter-spacing: 0.17142856px;
}

.btn-light {
     color: $primary !important;
     --#{$prefix}btn-disabled-bg: #f0f3f5;
     &:hover {
          color: $primary;
     }
}

.c-app:not(.c-legacy-theme):not(.c-dark-theme) .btn:not([class*='ghost']):not([class*='link']):not([class*='outline']):not([class*='transparent']) {
     border: 0;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
     background-color: #1985ac;
     border-color: #187da0;
}
.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show > .btn-info.dropdown-toggle {
     background-color: #39b2d5;
     border-color: #2eadd3;
}
.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
     background-color: #3a9d5d;
     border-color: #379457;
}
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active {
     color: $white;
     background-color: $primary;
     border-color: $primary;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show > .btn-outline-secondary.dropdown-toggle {
     background-color: $secondary;
     border-color: $secondary;
}
.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active {
     color: $white;
     background-color: #f86c6b;
     border-color: #f86c6b;
}

.btn-primary.focus,
.btn-primary:focus {
     background-color: #1b8eb7;
}

.btn-info {
     &:hover,
     &:focus {
          background-color: #43b6d7;
     }
}

.btn-secondary {
     color: #23282c;
     &:hover,
     &:focus {
          color: #23282c;
          background-color: #b9bec7;
     }
}

.btn-square {
     font-weight: 500;
     text-transform: uppercase;
     border-radius: rem(3);
}

.btn-no-wrap {
     white-space: nowrap;
}

.btn-fake-link {
     background-color: transparent;
     outline: none !important;
     border: 0 !important;
     padding: 0;
     font-weight: 400;
     &:hover {
          color: $primary;
     }
}

.btn-secondary {
     &.disabled {
          background-color: $gray-400;
     }
}

.btn-link {
     text-decoration: none;
     font-weight: 500;
     &:hover {
          text-decoration: underline;
     }
}

.upgrade-account {
     .price {
          font-size: 24px;
          padding: 5px 0 10px;
     }
}

.disabled {
     cursor: inherit !important;
}

.btn-active-account {
     min-width: 160px;
}

.simple-copy-button {
     cursor: pointer;
}

.btn-connect-gtm {
     display: flex;
     justify-content: center;
     align-items: center;
     width: 230px;
     margin: 0 auto;
     color: rgba(0, 0, 0, 0.54);
     font-family: Roboto;
     font-size: 14px;
     font-weight: 500;
     line-height: 18px;
     text-align: center;
     position: relative;
     text-transform: none;
     background: unset;
     box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.24), 0px 0px 1px rgba(0, 0, 0, 0.12) !important;
     padding-block: 6px !important;
     &:hover {
          box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12) !important;
          color: rgba(0, 0, 0, 0.54);
     }

     svg {
          margin-right: 20px;
          height: 18px;
          width: 18px;
     }
}

.external-link {
     display: inline-flex;
     align-items: center;
     gap: 5px;
}

.btn-create-rule {
     border-radius: 20px;
     font-size: 13px;
     font-weight: 700;
     line-height: 16px;
     text-align: center;
     text-transform: uppercase;
     letter-spacing: 0.18571427px;
     padding: 7px 17px;
     min-height: 35px;
     display: flex;
     align-items: center;

     i.fa-plus {
          font-weight: 600;
          margin-left: -3px;
          margin-right: 8px !important;
     }
}

.btn {
     &.square {
          padding: 8px 10px 6px !important;
          font-size: 10px;
          line-height: 10px;
          border: 1px solid $primary;
          &:hover {
               border-color: $primary;
               background-color: $primary;
               color: $white;
          }
     }
     &.btn-outline-primary {
          &:hover {
               color: $white;
               background: $primary;
               border-color: $primary;
          }
     }
     &.btn-outline-dark {
          color: #2f353a;
          border: 1px solid #2f353a;
          // text-transform: uppercase;
          &:hover {
               color: $white !important;
               background: #2f353a;
          }
     }
     &.btn-outline-secondary {
          border-color: #ced2d8;
          &:hover {
               background-color: #ced2d8;
               border-color: #ced2d8;
          }
     }
     &.btn-outline-danger {
          &:hover {
               color: #fff;
               background-color: #f86c6b;
               border-color: #f86c6b;
          }
     }
     &.btn-primary {
          &:hover {
               background-color: #1b8eb7;
               border-color: #1985ac;
          }
     }
     &.btn-success {
          &:hover {
               background-color: #3ea662;
               border-color: #3a9d5d;
          }
     }
     &-default {
          text-transform: capitalize;
     }
}

.btn-group > .btn:not([class*='ghost']):not([class*='link']):not([class*='outline']):not([class*='transparent']):not([class*='dropdown']) {
     box-shadow: 0 1px 1px 0 rgba(60, 75, 100, 0.14), 0 2px 1px -1px rgba(60, 75, 100, 0.12), 0 1px 3px 0 rgba(60, 75, 100, 0.2) !important;
}

.btn {
     &.btn-outline-admin {
          font-size: 12.25px;
          font-weight: 500;
          line-height: 1.5;
          padding: 4px 8px !important;
          &.btn-outline-danger {
               border: 1px solid #f86c6b !important;
               &:hover {
                    color: #fff;
                    background-color: #f86c6b;
               }
          }
     }
}

a.bg-gray-600:focus,
a.bg-gray-600:hover,
button.bg-gray-600:focus,
button.bg-gray-600:hover {
     background-color: $gray-700 !important;
}

.admin {
     .btn {
          &.btn-outline-primary {
               &:hover {
                    color: $white;
                    background: #20a8d8;
                    border-color: #20a8d8;
               }
          }
     }
}

@media (hover: none) {
     .btn-outline-primary:hover {
          color: #20a8d8;
          border-color: #20a8d8;
          background-color: #fff;
     }
}
