.loading-progress-wrapper {
     position: absolute;
     z-index: 1050;
     &.notFixed {
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
     }
     .loading-progress-mask {
          width: 100%;
          height: 100%;
          background: #ffff;
          opacity: 0.3;
     }
     .loading-progress-content {
          //     position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 350px;
          border-radius: 8px;
          padding: 8px 12px;
          background: #ffff;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          display: flex;
          flex-direction: column;
          align-items: center;
          z-index: 1050;
          .progress-bar-container {
               width: 100%;
               height: 4px;
               background: rgba(241, 243, 245, 1);
               border-radius: 4px;
               .progress-bar {
                    position: relative;
                    height: 100%;
                    border-radius: 4px;
                    background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(7, 100, 185, 1) 0%, rgba(0, 212, 255, 1) 100%);
                    transition: width 0.5s;
                    overflow: hidden;
                    .animation-bar {
                         height: 100%;
                         width: 20px;
                         background: linear-gradient(90deg, transparent, rgba(0, 212, 255, 1));
                         position: absolute;
                         top: 0;
                         animation: a1 1.2s infinite linear;
                    }
               }
          }
          .progress-text {
               margin-bottom: 0;
               font-size: 16px;
               font-weight: 500;
               line-height: 24px;
               letter-spacing: 0.27px;
               color: black;
               margin-top: 4px;
          }
          .wait-message {
               font-weight: 400;
               font-size: 12px;
               line-height: 14px;
               color: rgba(71, 85, 105, 1);
               display: flex;
               gap: 4px;
               margin-top: 4px;
               span {
                    svg {
                         path {
                              fill: rgba(32, 168, 216, 1) !important;
                         }
                    }
               }
          }
     }
}
@keyframes a1 {
     0% {
          left: 0;
     }
     100% {
          left: 100%;
     }
}
