.grayscale {
     filter: grayscale(100%);
}

.custom-input {
     div {
          cursor: pointer;
     }

     .react-select__placeholder {
          font-weight: 400;
     }
}

.dots-waiting:after {
     content: "";
     -webkit-animation: dots 1.5s steps(5, end) infinite;
     -moz-animation: dots 1.5s steps(5, end) infinite;
     animation: dots 1.5s steps(5, end) infinite;
     position: absolute;
}

@keyframes dots {
     25% {
          content: ".";
     }

     50% {
          content: "..";
     }

     75% {
          content: "...";
     }

     100% {
          content: "";
     }
}

@-webkit-keyframes dots {
     25% {
          content: ".";
     }

     50% {
          content: "..";
     }

     75% {
          content: "...";
     }

     100% {
          content: "";
     }
}

@-moz-keyframes dots {
     25% {
          content: ".";
     }

     50% {
          content: "..";
     }

     75% {
          content: "...";
     }

     100% {
          content: "";
     }
}

.card {
     // border: 1px solid $gray-200 !important;
     // box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.13) !important;
     color: $black;
     border: 1px solid #f0f3f5;
     box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
          0px 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
}

.react-select__multi-value {
     background-color: $light !important;
     border-radius: 9px !important;
     &__label{
          font-size: 85% !important;
     }
}

.react-select__multi-value__remove {
     cursor: pointer !important;

     svg {
          width: 10px;
          height: 10px;
     }
}

.add-height-c-app {
     min-height: calc(100vh + 120px);
}

.react-select__control {
     box-shadow: none !important;
     border: 0 !important;
     min-height: calc(1.5em + 0.75rem) !important;

     .react-select__placeholder {
          color: #2f353a !important;
          font-size: 14px !important;
     }

     .react-select__single-value {
          font-size: 14px;
          max-width: calc(100% - 12px);
     }

     .react-select__indicators {
          display: none;
     }

     .react-select__input {
          height: 21px;
          font-weight: 400 !important;
          box-sizing: content-box;
     }

     -webkit-appearance: none;
     -moz-appearance: none;
     background: transparent;
     background-image: url("/assets/icons/icon-select.svg");
     background-repeat: no-repeat;
     background-position-x: calc(100% - 12px);
     background-position-y: center;
     background-size: 6px;

     >div {
          &:last-child {
               display: none;
          }
     }
}

.css-13cymwt-control,.css-t3ipsp-control {
     box-shadow: none !important;
     border: 0 !important;
     min-height: calc(1.5em + 0.75rem) !important;
     -webkit-appearance: none;
     -moz-appearance: none;
     background: transparent;
     background-image: url("/assets/icons/icon-select.svg");
     background-repeat: no-repeat;
     background-position-x: calc(100% - 12px);
     background-position-y: center;
     background-size: 6px;
     >div {
          &:last-child {
               display: none;
          }
     }
}
.css-1hb7zxy-IndicatorsContainer {
     display: none !important;
}

.css-tr4s17-option {
     background-color: #f0f3f5 !important;
     color: #20a8d8 !important;
}

.react-select__menu .react-select__menu-list,
.custom-select__menu .custom-select__menu-list {
     &::-webkit-scrollbar {
          width: 3px;
          border-radius: 12px;
     }

     &::-webkit-scrollbar-track {
          width: 3px;
          background-color: #e2e6e9;
          border-radius: 2px;
          border-radius: 12px;
     }

     &::-webkit-scrollbar-thumb {
          background: #73818f;
          border-radius: 12px;
          border: none;
          min-height: 50px;
          width: 3px;
     }
}

.custom-select__menu {
     .custom-select__menu-list {
          padding-block: 0px;
          .custom-select__option:last-child {
               border-bottom-left-radius: 12px;
               border-bottom-right-radius: 12px;
          }
     }
}

.preview-listener-select {
     &:after {
          content: "";
          position: absolute;
          top: 50%;
          right: 16px;
          width: 7px;
          height: 7px;
          transform: translateY(-65%) rotate(45deg);
          border: 2px solid #212C36;
          border-top: none;
          border-left: none;
          transition: transform .3s;
     }

     div[class*=-control] {
          background-image: none;
          border-radius: 12px;
          font-size: 16px !important;
          padding: 4px 30px 3px 9px;
          font-weight: 500 !important;
          letter-spacing: 0.2px;
     }

     span+div[class*=-control] {
          position: relative;

          &:before {
               content: '';
               display: block;
               position: absolute;
               bottom: 0;
               left: 0;
               width: 100%;
               height: 5px;
               transform: translateY(-100%);
               background-color: transparent;
          }
     }

     .react-select__placeholder {
          font-size: 16px !important;
     }

     .react-select__menu {
          font-size: 16px;
          font-weight: 400;
          margin-top: 0;
          border: 1px solid #f0f3f5;
          border-radius: 0 0 12px 12px;
          box-shadow: -1px 1px 7px 0 #f0f3f5 !important;
          padding: 0;
          overflow: hidden;

          >div:first-child {
               padding: 0;

               &::-webkit-scrollbar-track {
                    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                    border-radius: 5px;
                    background-color: #F5F5F5;
               }

               &::-webkit-scrollbar {
                    width: 7px;
                    background-color: #F5F5F5;
               }

               &::-webkit-scrollbar-thumb {
                    border-radius: 5px;
                    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
                    background-color: #555;
               }
          }

          div[class*=-option] {
               padding-left: 18px;
               cursor: pointer;

               &:not(:last-child) {
                    border-bottom: 1px solid #dee2e5;
               }
          }
     }

     div[class*=-singleValue] {
          font-weight: 400;
          margin: 0;
     }

     div[class*=-multiValue] {
          font-weight: 400;
          padding-left: 7px;
          padding-bottom: 1px;
          border-radius: 15px;
          overflow: hidden;

          +div:not([class*=-multiValue]) {
               margin: 0;
               width: 0;
          }
     }

     .option-row {
          display: flex;
          align-items: center;
          padding-left: 0;

          .icon {
               height: 18px;
               margin-right: 10px;
          }
     }
}

.select-listener-wrapper {
     .select-listener {
          border-radius: 15px;
          max-width: 317px;
          margin-bottom: 18px;
          border: none !important;

          &:after {
               width: 5px;
               height: 5px;
               content: "";
               position: absolute;
               top: 50%;
               right: 16px;
               transform: translateY(-65%) rotate(45deg);
               border: 2px solid #212C36;
               border-top: none;
               border-left: none;
               transition: transform .3s;
          }

          div[class*=-menu] {
               z-index: 10;
               font-size: 16px;
               font-weight: 400;
               margin-top: 0;
               border: 1px solid #f0f3f5;
               border-radius: 0 0 12px 12px;
               box-shadow: -1px 1px 7px 0 #f0f3f5 !important;
               padding: 0;
               overflow: hidden;
     
               >div:first-child {
                    padding: 0;
     
                    &::-webkit-scrollbar-track {
                         -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                         border-radius: 5px;
                         background-color: #F5F5F5;
                    }
     
                    &::-webkit-scrollbar {
                         width: 7px;
                         background-color: #F5F5F5;
                    }
     
                    &::-webkit-scrollbar-thumb {
                         border-radius: 5px;
                         -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
                         background-color: #555;
                    }
               }
     
               div[class*=-option] {
                    padding-left: 18px;
                    cursor: pointer;
     
                    &:not(:last-child) {
                         border-bottom: 1px solid #dee2e5;
                    }
               }
          }

          div[class*=-placeholder] {
               color: #73818f;
               font-size: 14px;
               font-weight: 400;
               line-height: 17px;
               letter-spacing: 0.5px;
          }

          div[class*=control] {
               padding: 7px 30px 8px 20px;
               border: 2px solid #e2e6e9 !important;
               border-radius: 12px;
               background-image: none;
               font-size: 16px;
               font-weight: 500;
               letter-spacing: 0.2px;
          }

          &.segment{
               div[class*=control] {
                    padding: 0px 20px 0px 10px;
                    border-radius: 12px;
                    border: 0px solid #e2e6e9 !important;
                    background-image: none;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0.2px;
               }
          }

          div[class*=singleValue] {
               margin-left: 5px;
               font-weight: 400;
               margin: 0;
          }
          
          div[class*=-multiValue] {
               font-weight: 400;
               padding-left: 7px;
               padding-bottom: 1px;
               border-radius: 15px;
               overflow: hidden;
     
               +div:not([class*=-multiValue]) {
                    margin: 0;
                    width: 0;
               }
          }

          div[class*=-value-container],
          .react-select__value-container {
               padding: 0;
               margin: 0;

               input {
                    font-size: 14px !important;
                    letter-spacing: 0.5px;
               }
          }

          span+div[class*=-control] {
               position: relative;
     
               &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 5px;
                    transform: translateY(-100%);
                    background-color: transparent;
               }
          }

          .react-select__option {
               background-color: #DEEBFF;
               &--is-selected {
                    .option-row {
                         color: #20a8d8 !important;
                    }
               }
               
          }

          .option-row {
               font-size: 14px;
               font-weight: 500;
               line-height: 17px;
               letter-spacing: 0.5px;
               color: #7f8c9a !important;
               display: flex;
               align-items: center;
               padding-left: 0;

               .icon {
                    height: 18px;
                    margin-right: 8px;
               }
          }

          .react-select__menu {
               .react-select__menu-list {
                    border-radius: 0 0 12px 12px;
               }
          }

          .react-select__control {
               .react-select__value-container {
                    padding-left: 0;
                    padding-right: 0;
               }

               .react-select__input-container {
                    font-weight: 400;
               }

               .react-select__input {
                    padding: 1px 2px !important;
                    box-sizing: border-box;
               }
          }
     }

     &.menu-open .select-listener::after {
          transform: translateY(-65%) rotate(225deg);
          z-index: 10;
     }
}

.css-b62m3t-container {
     border: 1px solid #e4e7e9 !important;
     box-shadow: none !important;
     border-radius: 12px !important;
     background-color: #fff;
     min-height: 37px;
     &.is-invalid {
          border-color: #f86c6b !important;
     }
     div[class*=control] {
          border-radius: 12px;
     }
     .react-select__option {
          background-color: transparent !important;
          color: #20A8D8 !important;
          padding: 8px 12px;
          &:hover {
               background-color: #f3f5f6 !important;
          }
     }
}

.css-14jk2my-container,
.react-select--is-disabled {
     border: 2px solid #e4e7e9 !important;
     box-shadow: none !important;
     border-radius: 12px;
     overflow: hidden;
}

.revenue-layout {
     .react-select--is-disabled,
     .react-select__control--is-disabled {
          background-color: #e4e7ea;
          border: 1px solid #e2e6e9 !important;
     }
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
     background-color: transparent !important;

     >.ps__thumb-y {
          width: 6px !important;
     }
}

// split pane
.Resizer {
     background: #000;
     opacity: 0.2;
     z-index: 1;
     -moz-box-sizing: border-box;
     -webkit-box-sizing: border-box;
     box-sizing: border-box;
     -moz-background-clip: padding;
     -webkit-background-clip: padding;
     background-clip: padding-box;
}

.Resizer:hover {
     -webkit-transition: all 2s ease;
     transition: all 2s ease;

     &+.Pane {
          z-index: -1;
     }
}

.Resizer.horizontal {
     height: 11px;
     margin: -5px 0;
     border-top: 5px solid rgba(255, 255, 255, 0);
     border-bottom: 5px solid rgba(255, 255, 255, 0);
     cursor: row-resize;
     width: 100%;
}

.Resizer.horizontal:hover {
     border-top: 5px solid rgba(0, 0, 0, 0.5);
     border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
     width: 11px;
     margin: 0 -5px;
     border-left: 5px solid rgba(255, 255, 255, 0);
     border-right: 5px solid rgba(255, 255, 255, 0);
     cursor: col-resize;
}

.Resizer.vertical:hover {
     border-left: 5px solid rgba(0, 0, 0, 0.5);
     border-right: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.disabled {
     cursor: not-allowed;
}

.Resizer.disabled:hover {
     border-color: transparent;
}

.custom-control-input {
     &:checked~.custom-control-label {
          &::before {
               border-color: $primary;
               background-color: $primary;
          }
     }
}

.custom-date-wrapper.save-report {
     .custom-control-label {
          &::before,
          &::after {
               top: 0.2rem;
          }
     }
}

.custom-radio {
     .custom-control-label {
          &::before,
          &::after {
               top: 0.15625rem;
          }
     }
}

.custom-checkbox {
     .custom-control-input:disabled:checked~.custom-control-label::before {
          background-color: rgba(32, 168, 216, .5);
     }
}

.pagination  {
     .page-item {
          .page-link{
               padding-top: 0.5rem;
               padding-bottom: 0.5rem;
          }
          &.active .page-link {
               background-color: $primary;
               border-color: $primary;
          }
          &.disabled .page-link {
               color: #73818f;
               border-color: #c8ced3;
          }
     }
}

.dropdown {
     &-menu {
          border: 0;
          box-shadow: 0 4px 5px 0 rgba(60, 75, 100, .14), 0 1px 10px 0 rgba(60, 75, 100, .12), 0 2px 4px -1px rgba(60, 75, 100, .2);

          a.dropdown-item {
               color: #23282c;
               &:hover {
                    color: #181b1e;
               }
               &:active {
                    color: #ffffff;
               }
          }
     }
}

.invalid-feedback {
     font-size: 80%;
}

.tippy-box {
     font-size: .76562rem;
}

.modal.modal-static .modal-dialog {
     transform: none;
}

div.k-grid-norecords {
     font-size: 13px;
}

.tippy-content {
     // max-width: 200px;
     word-wrap: break-word;
}

.badge {
     &.bg-secondary {
          color: #000000;
     }
}

.saved-viewed-dropdown::after {
     display: inline-block;
     vertical-align: .255em;
     content: "";
     border-top: .3em solid;
     border-right: .3em solid transparent;
     border-bottom: 0;
     border-left: .3em solid transparent;
     margin-left: 6px;
}

.icon-outlined-none-focus:focus {
     outline: none;
}

.whitespace-normal {
     white-space: normal;
}

.saved-view-dropdown:has(.show) {
     .saved-view-tooltip-eye {
          display: none;
     }
}

.min-w-max-content {
     min-width: max-content !important;
}

a.text-danger:focus, a.text-danger:hover {
     color: #f52322 !important;
}

.create-account-popup {
     &.gtm-wizard,
     &.custom-destination-modal,
     &.switch-type-wizard {
          .modal {
               overflow-y: hidden;
               
               &.fade .modal-dialog {
                    opacity: 0.1;
                    transform: translateY(-25px);
                    transition: all 0.3s ease-out;
               }
               &.show.popup-transition .modal-dialog {
                    opacity: 1;
                    transform: none;
               }
          }
     }
}

.Toastify .Toastify {
     &__toast {
          animation-duration: 800ms;
     }
     &__close-button > svg {
          stroke: white;
          stroke-width: 0.5px;
     }
}

html[data-overlayscrollbars] {
     overflow: initial !important;
}

.sortable-multivalue {
     .react-select {
          &__multi-value__label {
               cursor: default;
          }
     }
}