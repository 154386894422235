.btn-group{

	.btn{
		--#{$prefix}btn-border-radius: #{$ll-btn-group-border-radius} !important;
		--#{$prefix}btn-font-weight: #{$ll-btn-group-font-weight};
		--#{$prefix}btn-border-width: #{$ll-btn-group-border-width};
		--#{$prefix}btn-border-color: #{$ll-btn-group-border-color};
		// --#{$prefix}btn-bg: #{$ll-btn-group-bg};
		--#{$prefix}btn-active-color: #{$ll-btn-group-color-label};
		--#{$prefix}btn-active-bg : #{$ll-btn-group-bg-label};
		--#{$prefix}btn-active-border-color : #{$ll-btn-group-bg-label};
		--#{$prefix}btn-font-size: #{$ll-btn-group-font-size};
		--#{$prefix}btn-line-height: #{$ll-btn-group-line-height};
		--#{$prefix}btn-padding-top: #{$ll-btn-group-padding-top};
		--#{$prefix}btn-padding-right: #{$ll-btn-group-padding-right};
		--#{$prefix}btn-padding-bottom: #{$ll-btn-group-padding-bottom};
		--#{$prefix}btn-padding-left: #{$ll-btn-group-padding-left};

		z-index: 1;
		&.dropdown-toggle{
			--#{$prefix}btn-active-bg : #{$ll-btn-group-bg-selected};
			--#{$prefix}btn-active-color : #{$ll-btn-group-color-selected};
		}

	}
	.btn-check + .btn {
		color: #424242 !important;
		background-color: white !important;
		&:hover {
			background-color: var(--#{$prefix}btn-active-bg) !important;
		}
	}
	.btn-check:checked + .btn,
	.btn-check:checked + .btn.btn-outline-light{
		color: var(--#{$prefix}btn-active-color) !important; //without important, we can't defeat Thanh An's code
		background-color: var(--#{$prefix}btn-active-bg) !important;
		background: linear-gradient(0deg, #2D3A44, #2D3A44),
		linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.04) 99.48%, rgba(0, 0, 0, 0.04) 99.49%);
		z-index: 0;
	}
	&.btn-default {
		.btn-check + .btn {
			&:hover {
				color: #fff !important;
				background-color: var(--#{$prefix}btn-active-bg) !important;
				border-color: white;
			}
		}
	}
}