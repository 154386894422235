.tip-video {
     border-radius: 12px;
     background: #f8f9fa;
     padding: 15px 15px;

     .content-text {
          strong {
               margin-right: 10px;
               font-size: 16px;
          }

          p {
               margin-bottom: 0;
               font-size: 16px;
               font-weight: 400;
               color: #121920;
          }
     }

     .watch-video-wrapper {
          .watch-video {
               cursor: pointer;
               margin-right: 10px;

               .icon-play {
                    width: 20px;
                    height: 20px;
                    margin-right: 5px;
               }
          }

          span {
               font-size: 11px;
               font-weight: 400;
               color: #73818f;
               letter-spacing: 0.15714286px;
               line-height: 13px;
          }
     }
}

.tooltip-tag {
     position: relative;

     .badge {
          max-height: 18px;
          padding: 3px 5px;
          line-height: 11px;
          text-transform: uppercase;
          background-color: transparent !important;
          cursor: pointer;

          &.segment {
               font-size: 10px;
               padding: 2px 4px;
          }

          &:hover {
               color: #fff !important;

               ~.block-tooltip-tag,
               .block-tooltip-tag {
                    display: block;
                    z-index: 1040;
               }
          }

          &.bg-info {
               border: solid 1px #63c2de;
               color: #63c2de;

               &:hover {
                    background-color: #63c2de !important;
               }
          }

          &.bg-primary {
               border: solid 1px #1a6daf;
               color: #1a6daf;

               &:hover {
                    background-color: #1a6daf !important;
               }
          }

          &.bg-success {
               border: solid 1px #4dbd74;
               color: #4dbd74;

               &:hover {
                    background-color: #4dbd74 !important;
               }
          }

          &.bg-secondary {
               border: solid 1px #73818f;
               color: #73818f;

               &:hover {
                    background-color: #73818f !important;
               }
          }

          &.bg-danger {
               border: solid 1px #dc3545;
               color: #dc3545;

               &:hover {
                    background-color: #dc3545 !important;
               }
          }
     }

     .block-tooltip-tag {
          position: absolute;
          min-width: auto;
          display: none;
          transition: 0.3s all linear;
          cursor: initial;
          z-index: 9;
          top: -10px;
          left: 50%;
          transform: translate(-50%, -100%);
          background-color: #212c36;
          border-radius: 12px;

          .icon-arrow-area {
               position: absolute;
               width: 34px;
               top: -1px;
               left: 50%;
               transform: translate(-50%, 100%) rotate(180deg);

               path {
                    fill: #212c36;
               }
          }

          .block-tooltip-text {
               padding: 5px;
               text-align: center;
               width: max-content;
               color: $white;
               background-color: #212c36;
               border-radius: rem(4);
               box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.13);
               min-width: 39px;

               &::-webkit-scrollbar {
                    width: 3px;
                    border-radius: 10px;
               }

               &::-webkit-scrollbar-track {
                    background: #19222b;
                    border-radius: 10px;
               }

               &::-webkit-scrollbar-thumb {
                    background: #f1f1f1;
                    border-radius: 10px;
               }

               p {
                    color: inherit;
                    font-size: 10px !important;
                    font-weight: 500 !important;
                    line-height: 12px !important;
                    text-align: center;
                    letter-spacing: 0.16666667px;
                    overflow: unset !important;
                    text-overflow: unset !important;
                    color: $white !important;

                    &:last-child {
                         margin-bottom: 0;
                    }
               }
          }
     }
}

.no-options {
     overflow-y: visible !important;
     text-align: center;

     .css-gg45go-NoOptionsMessage {
          padding: 8px 7px;
     }

     .no-options-message {
          position: relative;

          .icon-info {
               position: unset;
               top: unset;
               left: unset;
          }

          a {
               text-decoration: underline;
          }
     }

     .no-options-info {
          background-color: #19222b;
          width: 320px;
          max-width: 320px;
          position: absolute;
          left: calc(100% + 10px);
          top: calc(100% - 10px);
          border: none !important;

          span {
               color: #fff !important;
          }

          .arrow-preview {
               position: absolute;
               left: -10px;
          }

          .text-wrapper {
               padding: 1.25rem !important;
          }
     }

     &.bottom {
          .no-options-info {
               left: auto;
               right: 5px;
               top: calc(100% + 10px);
               border-top-left-radius: 12px;
               border-top-right-radius: unset;
          }

          .arrow-preview {
               left: auto;
               right: 0;
               top: -10px;
               transform: rotate(90deg);
          }
     }
}

.base-tags {
     display: flex;
     flex-wrap: wrap;
     align-items: center;
     justify-content: flex-start;

     .tag-item {
          cursor: pointer;
          border-radius: 4px;
          border: 1px solid rgb(195, 218, 254);
          background-color: rgb(235, 244, 255);
          padding: 2px 4px;
          line-height: 15px !important;
          margin: 4px;
          --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
          box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;

          &.allow-click:hover {
               color: #fff;
               border: 1px solid rgb(67, 65, 144);
               background-color: rgb(67, 65, 144);
          }
     }
}

.row-scroll {
     overflow-x: scroll;

     &::-webkit-scrollbar {
          height: 0px;
          width: 0px;
          background: transparent;
          /* make scrollbar transparent */
     }
}

.loading-overlay {
     position: absolute;
     top: 0;
     right: 0;
     bottom: 0;
     left: 0;
     display: flex;
     align-items: center;
     justify-content: center;
     z-index: 10;
     background-color: rgba(138, 138, 138, 0.1);
}