.goals {
     padding-bottom: 20px;
     .goalsContent {
          .triggers-rules {
               .default-rules {
                    .accordion-item.accordion-item-rule {
                         &.show {
                              .collapse {
                                   &.show {
                                        .row-rule-trigger-accordion {
                                             ul {
                                                  li {
                                                       .content {
                                                            background-color: #f0f3f5 !important;
                                                       }
                                                  }
                                             }
                                        }
                                   }
                              }
                         }
                    }
               }
               .triggers-pagination {
                    margin-top: 20px;
               }
          }

          .server-side-table {
               position: relative;
               .k-filtercell-operator {
                    display: none;
               }
               .table {
                    .download {
                         padding-top: 30px;
                         vertical-align: top;
                    }
               }
               .batch-id-header {
                    .k-link {
                         display: flex;
                         justify-content: space-around;
                         min-width: 115px;
                         align-items: center;
                    }
               }
               .custom-date-picker {
                    .close-filter {
                         position: absolute;
                         top: 12px;
                         right: 10px;
                    }
               }
               .batch-id {
                    text-align: center;
               }
               .k-header {
                    vertical-align: middle;
               }
               .table-head {
                    .k-column-title {
                         overflow: unset;
                         white-space: break-spaces;
                    }
               }
               .download-log {
                    text-decoration: underline;
                    cursor: pointer;
                    &:hover {
                         text-decoration: none;
                    }
                    .spinner-border {
                         width: 17px;
                         height: 17px;
                    }
               }
               .icon-sort {
                    margin-left: 2px;
                    transform: rotate(180deg);
                    height: 12px;

                    &.asc,
                    &.desc {
                         path {
                              fill: #000000;
                         }
                    }

                    &.desc {
                         transform: rotate(0);
                    }
               }
               margin-top: 20px;
               .k-grid {
                    border-radius: 12px
               }
               .k-grid-aria-root {
                    border-radius: 12px;
               }
               .k-grid-header {
                    padding-right: 0;
               }
               .k-grid-header, .k-header {
                    background-color: #8d9ba8;
                    font-weight: 500;
               }
               .k-grid-content::-webkit-scrollbar {
                    width: 1px;
                    height: 7px;
                    position: absolute;
                    top: 10px;
               }
               .k-grid-content::-webkit-scrollbar-track {
                    background-color: rgb(226, 225, 225);
                    border-bottom-left-radius: 9px;
                    border-bottom-right-radius: 9px;
               }
               .k-grid-content::-webkit-scrollbar-thumb {
                    background-color: #bdbcbc;
                    // border-bottom-left-radius: 9px;
                    // border-bottom-right-radius: 9px;
                    border-radius: 15px;
               }
          }

          .drag-drop-guide {
               display: flex;
               align-items: center;
               margin-bottom: 18px;
               color: #121920;
               font-family: Roboto;
               font-size: 16px;
               font-weight: 500;
               line-height: 22px;

               svg {
                    margin-top: -1px;
                    margin-right: 10px;
                    color: #c8ced3;
                    width: 15px;
                    height: 15px;
               }

               strong {
                    font-weight: 500;
                    margin-right: 5px;
               }
          }

          .triggers-rules {
               z-index: 1;

               .list-rules {
                    .accordion-item {
                         .collapse {
                              overflow: inherit;
                         }
                    }
               }

               .icon-trigger {
                    width: 16px;
                    height: 16px;

                    &.icon-action {
                         width: 20px;
                         height: 20px;
                    }
               }
          }

          .accordion-item {
               position: relative;
               border: 1px solid #e2e6e9;
               border-radius: 12px;
               background-color: transparent;
               margin-bottom: 10px;
               min-height: 45px;
               overflow: hidden;

               .details-conversion-row {
                    margin-bottom: 15px;

                    &.details-conversion-row-end {
                         margin-bottom: 10px;
                    }
               }

               .learn-how-to-write {
                    padding: 0 15px 3px 0;
                    text-decoration: underline;
                    text-transform: none;
                    position: relative;
                    color: #7f8c9a;

                    &::after {
                         content: '';
                         position: absolute;
                         top: calc(50% - 1px);
                         right: 0px;
                         width: 6px;
                         height: 6px;
                         transform: translateY(-50%) rotate(45deg);
                         border: 2px solid #7f8c9a;
                         border-left: none;
                         border-bottom: none;
                         margin-top: 2px;
                    }
               }

               .destination-conversions {
                    .row-rule {
                         padding: 4px 0px 0px;
                    }

                    .details-conversion {
                         .details-conversion-row {
                              margin-bottom: 15px;

                              &:last-child {
                                   margin-bottom: 10px;
                              }
                         }

                         .row {
                              margin-left: -30px;
                         }

                         p {
                              margin-bottom: 10px;
                         }

                         .btn-add-row {
                              margin-left: -15px;
                         }
                    }

                    .description {
                         // max-width: 80%;
                         font-size: 12px;
                    }

                    .parameter-checkbox + .details-conversion {
                         .insert-variable-dropdown ul {
                              // min-width: 290px;
                              border-radius: 12px;
                         }
                    }
               }

               .ecommerce {
                    .row-rule .select-react-customize {
                         color: #2f353a;
                    }
               }

               &.accordion-item-rule {
                    overflow: unset;
                    background-color: #f0f3f5;
                    border: none;

                    ul {
                         padding-left: 0;
                         margin: 0;
                         display: flex;
                         flex-wrap: wrap;
                         list-style-type: none;
                         margin-left: -5px;
                         margin-right: -5px;

                         li {
                              flex: 0 0 50%;
                              font-weight: 500;
                              padding: 5px;

                              p {
                                   white-space: nowrap;
                                   overflow: hidden;
                                   text-overflow: ellipsis;
                                   max-width: 90%;
                              }

                              a {
                                   color: #a3afb8;
                              }

                              .content {
                                   position: relative;
                                   display: inline-flex;
                                   align-items: center;
                                   justify-content: space-between;
                                   min-height: 35px;
                                   width: 100%;
                                   font-weight: 700;
                                   padding: 0 15px;
                                   background-color: #fff;
                                   border-radius: 8px;
                                   max-width: 445px;
                                   height: 100%;

                                   .name-wrapper {
                                        > p {
                                             white-space: normal;
                                             max-width: 80%;
                                             padding: 5px 0;
                                        }
     
                                        > .tooltip-tag  {
                                             margin-right: 15px;
                                        }
                                   }
                                   .tooltip-wrapper {
                                        cursor: pointer;
                                   }
                              }

                              .block-tooltip {
                                   top: 9px;
                                   min-width: 235px;
                                   &.custom-width {
                                        min-width: 285px;
                                   }

                                   svg {
                                        left: -10px;
                                   }

                                   .block-tooltip-text {
                                        &.custom-tooltip-text {
                                             p {
                                                  white-space: unset;
                                                  max-width: none;
                                             }
                                        }
                                        padding: 0.6375rem 0.7625rem;
                                   }
                              }
                         }
                    }

                    .accordion-body {
                         border-top: none;
                    }

                    .collapse {
                         overflow: hidden;

                         .option-item {
                              white-space: nowrap;

                              .block-tooltip {
                                   top: -10px;
                                   left: auto;
                                   right: 0;
                                   transform: translatey(-100%);
                                   min-width: inherit;
                                   background-color: #18222b;
                                   border-radius: 12px;

                                   svg {
                                        transform: rotate(-90deg) scaleY(-1);
                                        left: auto;
                                        right: 24px;
                                        top: auto;
                                        bottom: -14px;
                                   }

                                   .block-tooltip-text {
                                        max-height: inherit;

                                        p {
                                             max-width: inherit;
                                        }
                                   }
                              }
                         }
                    }
               }

               &.show {
                    overflow: inherit;
                    margin-bottom: 22px;
                    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);

                    .accordion-header {
                         &.accordion-rule {
                              border-bottom: none;
                         }

                         &::after {
                              transform: translateY(-25%) rotate(225deg);
                         }
                    }
               }

               &.disabled {
                    opacity: 0.5;
                    pointer-events: none;

                    .accordion-header:after {
                         display: none;
                    }
               }

               p {
                    padding-left: 0;
                    color: #000;
                    font-family: Roboto;
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0.1px;
               }

               h5 {
                    font-family: Roboto;
                    font-size: 15px;
                    font-weight: 600;
                    line-height: 22px;
               }

               label {
                    color: #73818f !important;
                    font-family: Roboto;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 17px;
                    letter-spacing: 0.5px;
               }

               input,
               select {
                    &.form-control {
                         min-height: 37px;
                    }
               }

               .accordion-header {
                    justify-content: flex-start;
                    min-height: 45px;
                    color: #000;
                    width: 100%;
                    border: none;
                    border-top-left-radius: 12px;
                    border-top-right-radius: 12px;
                    display: flex;
                    align-items: center;
                    padding: 12px 20px;
                    font-size: 18px;
                    line-height: 1.2em;
                    position: relative;
                    background-color: #f0f3f5;

                    &.consent {
                         padding-right: 40px;

                         .title {
                              padding: 0;
                              min-width: 330px;
                         }
                    }

                    &:after {
                         content: '';
                         position: absolute;
                         top: 22px;
                         right: 22px;
                         width: 7px;
                         height: 7px;
                         transform: translateY(-50%) rotate(45deg);
                         border: 2px solid #000;
                         border-top: none;
                         border-left: none;
                         transition: transform 0.3s;
                    }

                    p {
                         margin-bottom: 0;
                    }

                    .title {
                         font-family: 'Roboto';
                         min-width: 285px;
                         padding-right: 50px;
                         text-align: left;
                         color: #18222b;
                         font-size: 16px;
                         line-height: 24px;
                         letter-spacing: 0.26666668px;
                         font-weight: 500;
                    }

                    .description {
                         font-size: 13px;
                         font-weight: 300;
                         line-height: 20px;
                         letter-spacing: 0.086666666px;
                         text-align: left;
                         padding-right: 20px;
                    }
               }

               .accordion-body {
                    padding: 19px 20px 24px 20px;
                    z-index: 9;
                    text-align: left;
                    border-top: 1px solid #e2e6e9;

                    a.option {
                         &:hover {
                              text-decoration: none;
                         }
                    }

                    .block-tooltip-text {
                         p {
                              color: #fff;
                         }
                    }

                    h4 {
                         font-family: Poppins;
                         font-size: 15px;
                         font-weight: 600;
                         line-height: 22px;
                    }

                    .destination-conversions {
                         .description {
                              // max-width: 80%;
                              font-size: 13px;

                              a {
                                   font-weight: 500;
                                   text-decoration: underline;
                              }
                         }

                         p {
                              // color: #18222b;
                              font-family: Roboto;
                              font-size: 14px;
                              line-height: 17px;
                              letter-spacing: 0.5px;

                              &.description {
                                   font-size: 12px;
                                   font-weight: 300;
                                   letter-spacing: 0.08px;
                                   margin-bottom: 0;
                              }
                         }

                         .manage-conversions {
                              font-weight: 500;
                              margin-bottom: 5px;
                              color: #212c36;
                         }

                         .type-conversions {
                              font-weight: 500;
                              margin-bottom: 11px;
                              margin-top: 20px;
                              color: #212c36;
                         }

                         .form-group {
                              position: relative;

                              &.custom-script-container {
                                   .input-icons-custom {
                                        position: relative;
                                        min-width: 106px;

                                        .button-insert-variable {
                                             label {
                                                  margin-top: 0px !important;
                                             }
                                        }

                                        button {
                                             position: absolute;
                                             bottom: 0;
                                             left: -20px;
                                        }

                                        svg {
                                             left: 0;
                                             top: 44%;
                                             width: 14px;
                                             height: 14px;
                                             margin: 0;
                                        }

                                        label {
                                             text-decoration: underline;
                                             color: #73818f;
                                             margin-bottom: 0;
                                        }

                                        .insert-variable-dropdown {
                                             min-width: 309px;
                                             right: 0;
                                             left: auto;
                                             top: 10px;

                                             ul {
                                                  padding: 0;
                                                  overflow-x: hidden;
                                                  position: initial;
                                                  width: 100%;
                                                  border: none !important;
                                                  margin-bottom: 0;
                                                  border-radius: 12px;
                                                  box-shadow: none !important;
                                                  max-height: 280px;
                                                  margin-top: 0;

                                                  &.have-scroll {
                                                       width: calc(100% - 5px);

                                                       li {
                                                            padding-right: 9px;
                                                       }
                                                  }

                                                  &.script-custom-variable {
                                                       min-width: auto;
                                                  }
                                             }
                                        }

                                        .type-variable {
                                             svg {
                                                  top: 50%;
                                                  transform: translateY(-50%);
                                                  left: auto;
                                             }

                                             strong {
                                                  margin-right: 0px;
                                             }
                                        }

                                        .button-insert-variable {
                                             label {
                                                  margin-left: 5px;
                                                  margin-bottom: 11px;
                                                  text-decoration: none;
                                                  cursor: pointer;

                                                  &:hover {
                                                       text-decoration: underline;
                                                  }
                                             }
                                        }
                                   }
                              }

                              .form-control {
                                   padding: 6px 20px 6px 8px;
                                   border-radius: 12px;
                                   border: 1px solid #e2e6e9 !important;

                                   &.form-control[type='radio'] {
                                        border: none !important;
                                        background-color: transparent;
                                   }

                                   &.is-invalid {
                                        border-color: #f86c6b !important;
                                        padding-right: 46px;
                                   }

                                   &:disabled {
                                        background-image: url(/assets/icons/lock-dropdown.svg);
                                        background-size: 12px 13px;
                                        background-color: #e4e7ea;
                                        background-repeat: no-repeat;
                                        background-position: center right 20px;
                                   }
                              }
                         }

                         .purchase-google-ads {
                              display: flex;
                              align-items: center;
                              padding: 10px 0;
                         }

                         .tooltip-wrapper {
                              &.tooltip-purchase {
                                   .block-tooltip {
                                        top: -7px;
                                        left: 50%;
                                        transform: translate(-50%, -100%);
                                        /* min-width: inherit; */
                                        background-color: #18222b;
                                        border-radius: 12px;

                                        svg {
                                             width: 42px;
                                             bottom: 7px;
                                             transform: translate(-50%, 100%) rotate(263deg);
                                             color: #18222b;
                                             top: 82%;
                                             left: 52%;
                                        }

                                        .block-tooltip-text {
                                             border-top-left-radius: 12px;
                                        }
                                   }

                                   .block-tooltip-text {
                                        border-top-left-radius: 12px;
                                   }

                                   svg {
                                        top: 1px;
                                   }
                              }

                              .hover-show-tooltip {
                                   position: relative;
                              }
                         }

                         .row-rule {
                              .tooltip-wrapper {
                                   &.tooltip-event {
                                        position: absolute;
                                        right: 13px;
                                        top: 50%;
                                        transform: translateY(-50%);

                                        .block-tooltip {
                                             transform: translateX(-75%);
                                             top: -85px;

                                             svg {
                                                  width: 42px;
                                                  top: 55px;
                                                  bottom: auto;
                                                  left: 70%;
                                                  transform: translate(-50%, 100%) rotate(-90deg);
                                                  color: #18222b;
                                             }
                                        }

                                        &.tooltip-invalid {
                                             top: 26px;
                                        }
                                   }
                              }
                         }

                         .details-conversion {
                              position: relative;

                              label {
                                   margin-right: 16px;

                                   &::after {
                                        content: '';
                                        position: absolute;
                                        top: 47%;
                                        right: 15px;
                                        width: 7px;
                                        height: 7px;
                                        transform: translateY(-50%) rotate(45deg);
                                        border: 2px solid #000;
                                        border-top: none;
                                        border-left: none;
                                        transition: transform 0.3s;
                                   }

                                   &.show {
                                        &::after {
                                             top: 54%;
                                             transform: translateY(-50%) rotate(225deg);
                                        }
                                   }

                                   &.add-row {
                                        &::after {
                                             content: none;
                                        }
                                   }
                              }
                         }

                         .row-rule {
                              min-height: 46px;
                              padding-top: 0;

                              .tooltip-wrapper {
                                   &:hover {
                                        position: relative;
                                        z-index: 9;
                                   }
                              }

                              .consider-conversion {
                                   top: 3px;
                              }
                         }

                         .options-data-wrapper {
                              p {
                                   margin-bottom: 0;
                              }
                         }

                         a.option {
                              &:hover {
                                   text-decoration: none;
                              }
                         }

                         .input-icons {
                              position: relative;

                              .icon:not(.icon-c-s):not(.icon-custom-size) {
                                   height: 13px;
                                   width: 13px;
                              }

                              svg {
                                   position: absolute;
                                   right: 12px;
                                   top: 50%;
                                   transform: translateY(-50%);
                              }

                              .value-no-options-available {
                                   svg {
                                        position: relative;
                                        top: 50%;
                                        right: 5px;
                                   }
                              }

                              .form-control {
                                   &.input-content {
                                        &.is-invalid {
                                             background-position: right calc(2em + 0.1875rem) center;
                                        }
                                   }
                              }

                              &.input-icons-errors {
                                   .form-control.is-invalid {
                                        background-position: right calc(2em + 0.1875rem) center;
                                        padding-right: calc(2.6em + 0.75rem);
                                   }

                                   &.input-icons-field {
                                        svg {
                                             top: 50%;
                                        }
                                   }

                                   .value-no-options-available {
                                        svg {
                                             top: 50%;
                                        }
                                   }
                              }

                              li {
                                   min-height: 38px;

                                   &.variable-filter-bottom {
                                        height: 0px;
                                        min-height: auto;
                                   }
                              }

                              .type-variable {
                                   position: relative;
                                   padding: 0;

                                   strong {
                                        word-break: break-word;

                                        &:nth-of-type(2) {
                                             white-space: nowrap;
                                        }
                                   }

                                   svg {
                                        right: 15px;
                                   }

                                   svg {
                                        &.icon:not(.icon-c-s):not(.icon-custom-size) {
                                             height: 8px;
                                             width: 8px;
                                        }
                                   }
                              }

                              .insert-variable-dropdown {
                                   margin-top: -1px;
                                   list-style: none;
                                   position: absolute;
                                   left: 0;
                                   padding: 0px;
                                   z-index: 99;
                                   border: 1px solid #e4e7ea !important;
                                   box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.13) !important;
                                   max-height: 280px;
                                   background-color: #fff;
                                   width: 100%;
                                   border-radius: 12px;
                                   overflow: hidden;
                                   margin-bottom: 1rem;
                              }

                              ul {
                                   padding: 0;
                                   overflow-x: hidden;
                                   position: initial;
                                   width: 100%;
                                   border: none !important;
                                   margin-bottom: 0;
                                   border-radius: 12px;
                                   box-shadow: none !important;
                                   max-height: 280px;
                                   margin-top: 0;

                                   &.have-scroll {
                                        width: calc(100% - 5px);

                                        li {
                                             padding-right: 9px;

                                             &.variable-filter {
                                                  padding-right: 9px;
                                             }
                                        }
                                   }

                                   &::-webkit-scrollbar {
                                        width: 3px;
                                   }

                                   &::-webkit-scrollbar-track {
                                        width: 3px;
                                        background-color: #e2e6e9;
                                        border-radius: 2px;
                                        margin-top: 8px;
                                        margin-bottom: 8px;
                                   }

                                   &::-webkit-scrollbar-thumb {
                                        background: #73818f;
                                        width: 3px;
                                        border-radius: 12px;
                                        border: none;
                                        min-height: 50px;
                                   }

                                   li {
                                        padding: 5px 17px 5px 17px;

                                        &:hover {
                                             background: #f3f5f6;

                                             span {
                                                  color: #20a8d8 !important;
                                             }
                                        }

                                        &.variable-filter {
                                             background: #fff;
                                             z-index: 10;
                                             padding: 10px 17px 10px 17px;
                                        }
                                   }
                              }

                              .insert-variable-dropdown ul {
                                   padding: 0;

                                   li {
                                        &.border-bottom {
                                             padding: 5px 12px 5px 17px;
                                        }

                                        .form-control {
                                             min-width: auto;
                                        }
                                   }
                              }
                         }

                         .parameter-field {
                              .include-data-tooltip {
                                   input {
                                        z-index: 10;
                                   }

                                   label {
                                        cursor: pointer;
                                   }
                              }

                              .browser-step4-form {
                                   svg {
                                        width: auto;
                                        height: 14px;
                                   }
                              }
                         }

                         .select-react-customize {
                              .select-wrapper > input {
                                   background: #fff;
                                   background-image: url(/assets/icons/icon-select.svg);
                                   background-repeat: no-repeat;
                                   background-position-x: calc(100% - 9px);
                                   background-position-y: center;
                                   background-size: 6px;

                                   &.is-invalid {
                                        border: 1px solid #f86c6b !important;
                                        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23f86c6b' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f86c6b' stroke='none'/%3e%3c/svg%3e");
                                        background-repeat: no-repeat;
                                        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
                                   }
                              }

                              .select-wrapper > input[disabled] {
                                   background: transparent;
                              }
                         }
                    }

                    .description {
                         p {
                              word-break: break-word;
                              // color: #18222b;
                              font-family: 'Roboto';
                              font-size: 14px;
                              font-weight: 400;
                              line-height: 17px;
                              letter-spacing: 0.5px;

                              code {
                                   font-size: 15px;
                              }
                         }
                    }
               }

               .drag-drop-guide {
                    display: flex;
                    align-items: center;
                    margin-bottom: 18px;
                    color: #121920;
                    font-family: Roboto;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 22px;

                    svg {
                         margin-top: -1px;
                         margin-right: 10px;
                         color: #c8ced3;
                         width: 15px;
                         height: 15px;
                    }

                    strong {
                         font-weight: 500;
                         margin-right: 5px;
                    }
               }

               // .info-data-wrap {
               //      width: 45%;
               // }

               // .action-data-wrap {row-rule
               //      width: 35%;
               //      position: relative;
               // }

               .options-data-wrapper {
                    width: 65%;

                    &.expand {
                         width: 80%;
                    }

                    .option-item3:not(:first-child):before {
                         content: '';
                         display: block;
                         position: absolute;
                         top: 50%;
                         left: 0;
                         width: 1px;
                         height: 20px;
                         transform: translateY(-50%);
                         background-color: #b5dce9;
                    }
               }

               .row-rule {
                    .option-item2.option-item {
                         &::before {
                              content: '';
                              display: block;
                              position: absolute;
                              top: 50%;
                              left: 0;
                              width: 2px;
                              height: 20px;
                              transform: translateY(-50%);
                              background-color: #b5dce9;
                         }

                         .block-tooltip .block-tooltip-text p {
                              text-transform: none;
                         }
                    }

                    .option-custom {
                         color: #a3afb8;

                         &.option-item:hover:not(.switch-item) .option {
                              background-color: transparent;
                              text-decoration: none;
                         }

                         a.option {
                              color: #a3afb8;
                              align-items: center;
                              padding: 13px 14px;
                              font-size: 15px;

                              .error {
                                   color: #f86b6c;
                              }
                         }

                         p.template {
                              padding: 15px 14px;
                         }

                         .icon-action {
                              width: 20px;
                              height: 20px;
                              color: #20a8d8;
                         }

                         .select-label {
                              color: #2f353a;
                         }
                    }
               }

               .triggers-rules {
                    .row-rule {
                         h4 {
                              font-family: Poppins;
                              font-size: 15px;
                              font-weight: 600;
                              line-height: 22px;
                         }
                    }

                    p {
                         font-family: Roboto;
                         font-size: 15px;
                         font-weight: 400;
                         line-height: 20px;
                         letter-spacing: 0.1px;
                    }

                    .datalayer-variables {
                         p {
                              font-size: 14px;
                              max-width: 480px;
                         }
                    }

                    .row-rule {
                         .info-wrap {
                              // min-width: 45%;
                              max-width: 70%;
                         }

                         h4 {
                              // max-width: 80%;
                              padding: 10px 0;
                         }

                         .label-gtm {
                              background-color: #58afe0;
                              font-weight: 500;
                              border-radius: 3.25rem;
                              padding: 0.375rem 0.75rem;
                              font-size: 0.875rem;
                              line-height: 1.5;
                              text-align: center;
                              vertical-align: middle;
                              color: #fff;
                         }

                         .label-gtm-1 {
                              background-color: #2f353a;
                         }
                    }
               }

               .row-rule {
                    border-radius: 12px;
                    background: #f0f3f5;
                    padding-left: 12px;
                    margin-bottom: 8px;

                    .info-wrap {
                         display: flex;
                         align-items: center;
                         justify-content: space-between;
                         padding-right: 15px;
                    }

                    &.row-rule-trigger {
                         margin-bottom: 0;

                         .info-rule {
                              min-width: 55%;
                         }

                         .accordion-rule {
                              width: auto;
                              justify-content: flex-end;

                              span {
                                   margin-right: 22px;
                                   color: #708393;
                                   font-family: Roboto;
                                   font-size: 14px;
                                   font-weight: 400;
                                   line-height: 17px;
                                   text-align: right;
                                   letter-spacing: 0.2px;
                              }

                              &:after {
                                   content: '';
                                   position: absolute;
                                   top: 21px;
                                   right: 26px;
                                   width: 6px;
                                   height: 6px;
                                   transform: translateY(-50%) rotate(45deg);
                                   border: 1px solid #73818f;
                                   border-top: none;
                                   border-left: none;
                                   transition: transform 0.3s;
                              }

                              &.show {
                                   &::after {
                                        top: 22px;
                                        transform: translateY(-25%) rotate(225deg);
                                   }
                              }
                         }
                    }

                    &.row-rule-default {
                         h4 {
                              max-width: 80%;
                         }

                         .accordion-rule {
                              background-color: #fff;
                         }

                         // .info-wrap {
                         //      min-width: 30%;
                         // }

                         .option-item .form-group {
                              .partner-input {
                                   border: none;
                              }
                         }

                         .options-data-wrapper {
                              width: 70%;
                         }

                         .option-item3,
                         .option-item2 {
                              &::before {
                                   content: none;
                              }
                         }

                         .option-item3 {
                              padding: 0 15px;
                              position: relative;

                              input {
                                   background-color: #f1f3f5;
                                   width: 250px;
                                   padding-right: 65px;
                              }

                              button {
                                   position: absolute;
                                   right: 16px;
                                   top: 50%;
                                   transform: translateY(-50%);
                                   cursor: pointer;
                                   font-weight: 500;
                                   margin: 0;
                                   padding: 8px;

                                   &::before {
                                        content: '';
                                        display: block;
                                        position: absolute;
                                        top: 50%;
                                        left: 0;
                                        width: 2px;
                                        height: 20px;
                                        transform: translateY(-50%);
                                        background-color: #b5dce9;
                                   }
                              }
                         }
                    }

                    .icon-grip-lines {
                         width: 12px;
                         height: 6px;
                         margin-right: 12px;
                         min-width: 12px;
                    }

                    h4 {
                         margin-bottom: 0;
                         font-family: Poppins;
                         font-size: 15px;
                         font-weight: 600;
                         line-height: 22px;
                    }

                    .tooltip-wrapper {
                         position: relative;
                         padding-bottom: 3px;
                         margin-left: 10px;

                         svg {
                              width: 12px;
                              left: -10px;
                         }

                         > svg path {
                              fill: #20a8d8 !important;
                         }
                    }

                    &.template-conversion .tooltip-wrapper > svg path {
                         fill: #73818f !important;
                    }

                    .option-item {
                         position: relative;
                         display: flex;
                         margin-left: -1px;
                         cursor: pointer;

                         &:first-child::before {
                              content: none;
                         }
                         &:before {
                              content: '';
                              display: block;
                              position: absolute;
                              top: 50%;
                              left: 0;
                              width: 1px;
                              height: 20px;
                              transform: translateY(-50%);
                              background-color: #b5dce9;
                         }

                         &:hover:not(.switch-item) .option {
                              background-color: #b5dce9;
                         }

                         &.switch-item {
                              .option {
                                   border-top-right-radius: 12px;
                                   border-bottom-right-radius: 12px;
                              }
                         }

                         .option {
                              align-items: center;
                         }

                         .block-tooltip {
                              top: 0;
                              left: 50%;
                              transform: translate(-50%, -100%);
                              min-width: inherit;
                              background-color: #18222b;
                              border-radius: 12px;

                              .block-tooltip-text {
                                   padding: 6px 8px;
                                   text-align: center;
                                   white-space: nowrap;
                                   border-radius: 5px;

                                   p {
                                        font-size: 10px;
                                        font-weight: 500;
                                        line-height: 12px;
                                        letter-spacing: 0.16666667px;
                                        color: #fff;
                                   }
                              }

                              .arrow-tooltip-preview {
                                   width: 42px;
                                   top: auto;
                                   bottom: 7px;
                                   left: 50%;
                                   transform: translate(-50%, 100%) rotate(180deg);
                                   color: #18222b;
                              }

                              &.switch-item-disable {
                                   top: 0;
                                   right: 100%;
                                   transform: translate(50%, -100%);
                                   left: auto;

                                   .arrow-tooltip-preview {
                                        left: 65%;
                                   }
                              }
                         }
                    }

                    .option {
                         position: relative;
                         display: flex;
                         padding: 15px 14px;
                         align-items: center;

                         &-item-delete {
                              padding: 0 10px;
                              min-height: 46px;
                         }
                    }

                    .icon-delete {
                         width: 20px;
                         height: auto;
                    }

                    .switch-item .option {
                         padding: 12px 14px;
                    }
               }

               .row-rule-trigger-accordion {
                    font-weight: 500;
                    padding: 5px 20px 27px 40px;

                    .description {
                         margin-bottom: 15px;
                    }
               }

               .reset-to-default-box {
                    color: #73818f;
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 19px;
                    letter-spacing: 0;
                    padding-left: 4px;
                    margin-top: 14px;

                    .description {
                         color: inherit;
                         font-size: inherit;
                         font-weight: inherit;
                         line-height: inherit;
                         letter-spacing: inherit;
                    }

                    .btn {
                         font-size: inherit;
                         color: inherit;
                         text-transform: inherit;
                         text-decoration: underline;
                         padding: 0;
                         margin-left: 3px;

                         &:hover {
                              text-decoration: none;
                         }
                    }
               }

               .list-fields .variable label {
                    margin-left: 2px;
               }

               .default-rules {
                    background-color: #f0f3f5;
                    padding: 20px 20px 14px;
                    margin: 20px -20px;

                    .headline {
                         display: flex;
                         align-items: center;
                         margin-bottom: 8px;

                         .tooltip-wrapper {
                              position: relative;
                              padding-bottom: 3px;
                              margin-left: 5px;

                              > svg {
                                   height: 12px;

                                   path {
                                        fill: #73818f !important;
                                   }
                              }
                         }
                    }

                    h5 {
                         color: #18222b;
                         font-size: 16px;
                         font-weight: 500;
                         line-height: 24px;
                         letter-spacing: 0.26666668px;
                         margin-bottom: 0;
                    }

                    .row-rule {
                         background-color: #fff;
                         padding: 0 15px;

                         h4 {
                              margin: 0;
                         }

                         .option-item:last-child:after {
                              content: '';
                              display: block;
                              position: absolute;
                              top: 50%;
                              right: 0;
                              width: 1px;
                              height: 20px;
                              transform: translateY(-50%);
                              background-color: #b5dce9;
                         }
                    }

                    .rule-loading-overlay {
                         position: absolute;
                         width: 100%;
                         height: 100%;
                         top: 0;
                         left: 0;
                         display: flex;
                         align-items: center;
                         justify-content: center;
                         background-color: rgba(240, 243, 245, 0.4);
                         z-index: 10;
                    }
               }
          }

          .learn-how-to-write {
               padding: 0 15px 3px 0;
               text-decoration: underline;
               text-transform: none;
               position: relative;
               color: #7f8c9a;

               &::after {
                    content: '';
                    position: absolute;
                    top: calc(50% - 1px);
                    right: 0px;
                    width: 6px;
                    height: 6px;
                    transform: translateY(-50%) rotate(45deg);
                    border: 2px solid #7f8c9a;
                    border-left: none;
                    border-bottom: none;
                    margin-top: 2px;
               }
          }

          .learn-how-to-write {
               padding-right: 15px;
               margin-left: 9px;
          }

          .default-rules {
               .headline .tooltip-wrapper {
                    margin-left: 7px;
               }
          }

          .default-rules {
               background-color: #f0f3f5;
               padding: 20px 20px 14px;
               margin: 20px -20px;

               .headline {
                    display: flex;
                    align-items: center;
                    margin-bottom: 8px;

                    .tooltip-wrapper {
                         position: relative;
                         padding-bottom: 3px;
                         margin-left: 5px;

                         > svg {
                              height: 12px;

                              path {
                                   fill: #73818f !important;
                              }
                         }
                    }
               }

               h5 {
                    color: #18222b;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 24px;
                    letter-spacing: 0.26666668px;
                    margin-bottom: 0;
               }

               .row-rule {
                    background-color: #fff;
                    padding: 0 15px;

                    h4 {
                         margin: 0;
                    }

                    .option-item:last-child:after {
                         content: '';
                         display: block;
                         position: absolute;
                         top: 50%;
                         right: 0;
                         width: 1px;
                         height: 20px;
                         transform: translateY(-50%);
                         background-color: #b5dce9;
                    }
               }
          }

          .default-rules {
               .accordion-body {
                    background-color: #fff;
               }

               .accordion-item-rule {
                    background-color: #fff;

                    .collapse {
                         overflow: inherit;
                    }
               }

               .row-rule {
                    .option-item {
                         &.status-default-rule {
                              .block-tooltip {
                                   .block-tooltip-text {
                                        span {
                                             width: 300px;
                                             white-space: initial;
                                             display: block;
                                        }
                                   }
                              }
                         }
                    }

                    .option-item:last-child {
                         &:after {
                              content: none;
                         }
                    }
               }

               .row-rule-trigger-accordion {
                    border-radius: 12px;

                    .content {
                         background-color: #f0f3f5;

                         .tooltip-wrapper {
                              position: relative;
                              margin-left: 10px;

                              .block-tooltip {
                                   min-width: 303px;
                              }

                              p {
                                   white-space: unset;
                                   max-width: none;
                              }
                         }
                    }
               }
          }

          .info-title {
               padding: 5px 10px 4px 0;
               max-width: 1100px;

               h4 {
                    display: contents;
               }

               > * {
                    display: inline-block;
               }

               & + .tooltip-tag {
                    margin-left: 0;
               }
          }

          .drag-drop-actions {
               margin-top: 20px;

               .btn {
                    border-radius: 20px;
                    font-size: 13px;
                    font-weight: 700;
                    line-height: 16px;
                    text-align: center;
                    text-transform: uppercase;
                    letter-spacing: 0.18571427px;
                    padding: 7px 17px;
                    min-height: 35px;
                    display: flex;
                    align-items: center;

                    &:not(:last-child) {
                         margin-right: 10px;
                    }

                    i {
                         font-weight: 600;
                         margin-left: -3px;
                         margin-right: 8px !important;
                    }
               }

               .btn-save-change {
                    margin-right: 2px;

                    svg {
                         height: 9px;
                         margin-right: 5px;
                    }
               }

               .btn-link {
                    color: #7f8c9a;
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 17px;
                    letter-spacing: 0.4px;
                    text-align: center;
                    text-decoration: underline;
                    text-transform: inherit;
                    padding: 0;

                    svg {
                         height: 8px;
                         margin-left: 6px;
                    }
               }
          }

          .row-rule-default {
               h4 {
                    max-width: 80%;
               }

               .accordion-rule {
                    background-color: #fff;
               }

               // .info-wrap {
               //      min-width: 30%;
               // }

               .option-item .form-group {
                    .partner-input {
                         border: none;
                    }
               }

               .options-data-wrapper {
                    width: 70%;
               }

               .option-item3,
               .option-item2 {
                    &::before {
                         content: none;
                    }
               }

               .option-item3 {
                    padding: 0 15px;
                    position: relative;

                    input {
                         background-color: #f1f3f5;
                         width: 250px;
                         padding-right: 65px;
                    }

                    button {
                         position: absolute;
                         right: 16px;
                         top: 50%;
                         transform: translateY(-50%);
                         cursor: pointer;
                         font-weight: 500;
                         margin: 0;
                         padding: 8px;

                         &::before {
                              content: '';
                              display: block;
                              position: absolute;
                              top: 50%;
                              left: 0;
                              width: 2px;
                              height: 20px;
                              transform: translateY(-50%);
                              background-color: #b5dce9;
                         }
                    }
               }
          }

          // Option Item ////////////////////////////////////////////////////////////////////////
          .option-custom {
               color: #a3afb8;

               &.option-item:hover:not(.switch-item) .option {
                    background-color: transparent;
                    text-decoration: none;
               }

               a.option {
                    color: #a3afb8;
                    align-items: center;
                    // padding: 13px 14px;
                    font-size: 15px;

                    .error {
                         color: #f86b6c !important;
                    }
               }

               p.template {
                    padding: 15px 14px;
               }

               .icon-action {
                    width: 20px;
                    height: 20px;
                    color: #20a8d8;
               }

               .select-label {
                    color: #2f353a;
               }
          }

          .row-rule {
               .option-item {
                    &.status-default-rule {
                         .block-tooltip {
                              .block-tooltip-text {
                                   span {
                                        width: 300px;
                                        white-space: initial;
                                        display: block;
                                   }
                              }
                         }
                    }
               }

               .option-item:last-child {
                    &:after {
                         content: none;
                    }
               }
          }

          .option-item .form-group {
               .partner-input {
                    border: none;
               }
          }

          .option-item {
               position: relative;
               display: flex;
               margin-left: -1px;
               cursor: pointer;

               // &:before {
               //     content: '';
               //     display: block;
               //     position: absolute;
               //     top: 50%;
               //     left: 0;
               //     width: 1px;
               //     height: 20px;
               //     transform: translateY(-50%);
               //     background-color: #b5dce9;
               // }

               &:hover:not(.switch-item) .option {
                    background-color: #b5dce9;
               }

               &.switch-item {
                    .option {
                         border-top-right-radius: 12px;
                         border-bottom-right-radius: 12px;
                    }
               }

               .option {
                    align-items: center;
               }

               .block-tooltip {
                    top: 0;
                    left: 50%;
                    transform: translate(-50%, -100%);
                    min-width: inherit;
                    background-color: #18222b;
                    border-radius: 12px;

                    .block-tooltip-text {
                         padding: 6px 8px;
                         text-align: center;
                         white-space: nowrap;
                         border-radius: 5px;

                         p {
                              font-size: 10px;
                              font-weight: 500;
                              line-height: 12px;
                              letter-spacing: 0.16666667px;
                              color: #fff;
                         }
                    }

                    .arrow-tooltip-preview {
                         width: 42px;
                         top: auto !important;
                         bottom: 7px !important;
                         left: 50% !important;
                         transform: translate(-50%, 100%) rotate(180deg) !important;
                         color: #18222b;
                    }

                    &.switch-item-disable {
                         top: 0;
                         right: 100%;
                         transform: translate(50%, -100%);
                         left: auto;

                         .arrow-tooltip-preview {
                              left: 65%;
                         }
                    }
               }
          }

          .row-rule {
               background-color: #fff;
               padding: 0 15px;

               h4 {
                    margin: 0;
               }

               // .option-item:last-child:after {
               //     content: '';
               //     display: block;
               //     position: absolute;
               //     top: 50%;
               //     right: 0;
               //     width: 1px;
               //     height: 20px;
               //     transform: translateY(-50%);
               //     background-color: #b5dce9;
               // }
          }

          .option-item {
               &.variable-trigger-conversion {
                    .block-tooltip {
                         top: 0px;
                         padding: 0;

                         svg {
                              top: -6px;
                         }

                         &.lookup-rule-conversion {
                              top: 2px;
                              left: 77%;
                              transform: translate(-50%, -100%);
                              min-width: inherit;
                              background-color: #18222b;
                              border-radius: 12px;
                         }

                         &.lookup-rule-trigger {
                              top: -6px;

                              svg {
                                   top: -8px;
                              }
                         }

                         .block-tooltip-text {
                              p {
                                   margin-bottom: 0;
                                   font-size: 15px;
                                   font-weight: 400;
                                   line-height: 20px;
                                   letter-spacing: 0.08px;
                                   word-break: break-word;
                              }

                              button {
                                   line-height: 13px;
                                   padding: 0.125rem 0.4375rem;
                                   background-color: #fff;
                                   color: #20a8d8;
                              }
                         }
                    }
               }
          }

          .option-item {
               &.variable-trigger-conversion {
                    img {
                         margin-right: -4px;
                    }
               }

               span {
                    color: #73818f;
                    font-family: Roboto;
                    font-size: 11px;
                    font-weight: 400;
                    line-height: 13px;
                    letter-spacing: 0.15714286px;
               }
          }

          .default-rules {
               .row-rule {
                    .option-item {
                         &.variable-trigger-conversion {
                              &:last-child {
                                   &::after {
                                        content: none;
                                   }
                              }
                         }
                    }
               }
          }

          .row-rule {
               .option-item {
                    &.variable-trigger-conversion {
                         .block-tooltip {
                              top: 0px;
                              padding: 0;

                              svg {
                                   top: -6px;
                                   transform: rotate(-90deg) scaleY(-1);
                              }

                              .block-tooltip-text {
                                   p {
                                        margin-bottom: 0;
                                        font-size: 15px;
                                        font-weight: 400;
                                        line-height: 20px;
                                        letter-spacing: 0.08px;
                                        word-break: break-word;
                                   }

                                   button {
                                        line-height: 13px;
                                        padding: 0.125rem 0.4375rem;
                                        background-color: #fff;
                                        color: #20a8d8;
                                   }
                              }
                         }
                    }
               }
          }

          .option-custom {
               &.variable-trigger-conversion {
                    .icon-trigger-lookup,
                    .icon-trigger {
                         position: relative;
                         top: 2px;
                    }
               }
          }

          //-----------------------------------------------------------------------------------------//

          .datalayer-variables {
               // padding: 5px 20px 5px 0px !important;
               max-width: 734.5px !important;
               align-items: center !important;

               .content {
                    max-width: none !important;
               }

               .rule-trigger-conversion {
                    padding-right: 0 !important;

                    .variable-trigger-conversion {
                         img {
                              margin-right: 12px;
                         }
                    }

                    .option-item {
                         position: relative;

                         .block-tooltip {
                              top: -7px;
                              left: 50%;
                              transform: translate(-50%, -100%);
                              min-width: fit-content;
                              background-color: #18222b;
                              border-radius: 12px;

                              .arrow-tooltip-preview {
                                   width: 42px;
                                   top: auto;
                                   bottom: 7px;
                                   left: 50%;
                                   transform: translate(-50%, 100%) rotate(180deg);
                                   color: #18222b;
                              }

                              .block-tooltip-text {
                                   padding: 6px 8px;
                                   text-align: center;
                                   white-space: nowrap;
                                   border-radius: 5px;

                                   p {
                                        font-size: 10px;
                                        font-weight: 500;
                                        line-height: 12px;
                                        letter-spacing: 0.16666667px;
                                        color: #fff;
                                   }
                              }
                         }
                    }

                    .variable-trigger-conversion {
                         margin-right: 12px;
                    }
               }

               .center-spinner {
                    display: flex;
                    align-items: center;
                    width: 31px;
                    height: 100%;
                    margin-right: 10px;
                    .spinner-border {
                         width: 1rem;
                         height: 1rem;
                         border-width: 0.2rem;
                    }
               }

               span.option-item {
                    padding: 0 10px;
                    .k-chart-surface {
                         span {
                              height: 35px !important;
                              svg {
                                   height: 75%;
                                   left: unset !important;
                                   top: calc(50% - 18px) !important;
                                   position: absolute;
                                   g {
                                        path {
                                             fill: transparent;
                                        }
                                        g {
                                             g:nth-child(3) {
                                                  path {
                                                       stroke: rgba(0, 0, 0, 0.5);
                                                  }
                                             }
                                             path {
                                                  fill: $primary;
                                                  stroke: $primary;
                                             }
                                        }
                                   }
                              }
                         }
                    }
               }
          }

          .datalayer-variables.fix-width {
               max-width: none !important;
          }

          .row-rule-trigger-accordion {
               .datalayer-variables {
                    &.rule-trigger-conversion {
                         .variable-path {
                              .variable-path-text {
                                   top: -80px;
                                   // left: calc(-80% - 15px);
                                   transform: none;
                                   left: auto;
                                   right: 45px;

                                   .code-preview {
                                        max-width: 500px;
                                   }
                              }
                         }
                    }

                    .content {
                         max-width: none;
                    }
               }
          }

          .datalayer-variables {
               &.rule-trigger-conversion {
                    .variable-path {
                         .variable-path-text {
                              transform: translateX(-87%);
                              top: -73px;
                              left: calc(-64% - 15px);

                              .code-preview {
                                   padding: 17px 17px;

                                   svg {
                                        left: 99%;
                                        bottom: 10px;
                                        top: auto;
                                        transform: rotateZ(180deg);
                                        height: 30px !important;
                                   }
                              }

                              .card-body {
                                   padding: 0;
                                   margin-bottom: 5px;

                                   p {
                                        line-height: 17px;
                                   }
                              }
                         }
                    }

                    .option-item {
                         span {
                              color: #73818f;
                              font-family: Roboto;
                              font-size: 11px;
                              font-weight: 400;
                              line-height: 13px;
                              letter-spacing: 0.15714286px;
                         }
                    }
               }
          }

          .accordion-item {
               &.accordion-item-rule {
                    .collapse {
                         .option-item {
                              &.variable-trigger-conversion {
                                   .block-tooltip {
                                        top: 0px;
                                        padding: 0;

                                        svg {
                                             top: -6px;
                                        }

                                        &.lookup-rule-conversion {
                                             top: 2px;
                                             left: 77%;
                                             transform: translate(-50%, -100%);
                                             min-width: inherit;
                                             background-color: #18222b;
                                             border-radius: 12px;
                                        }

                                        &.lookup-rule-trigger {
                                             top: -6px;

                                             svg {
                                                  top: -8px;
                                             }
                                        }

                                        .block-tooltip-text {
                                             p {
                                                  margin-bottom: 0;
                                                  font-size: 15px;
                                                  font-weight: 400;
                                                  line-height: 20px;
                                                  letter-spacing: 0.08px;
                                                  word-break: break-word;
                                             }

                                             button {
                                                  line-height: 13px;
                                                  padding: 0.125rem 0.4375rem;
                                                  background-color: #fff;
                                                  color: #20a8d8;
                                             }
                                        }
                                   }
                              }
                         }
                    }

                    .option-item {
                         &.variable-trigger-conversion {
                              img {
                                   margin-right: -4px;
                              }
                         }

                         span {
                              color: #73818f;
                              font-family: Roboto;
                              font-size: 11px;
                              font-weight: 400;
                              line-height: 13px;
                              letter-spacing: 0.15714286px;
                         }
                    }
               }

               .default-rules {
                    .row-rule {
                         .option-item {
                              &.variable-trigger-conversion {
                                   &:last-child {
                                        &::after {
                                             content: none;
                                        }
                                   }
                              }
                         }
                    }
               }

               .row-rule {
                    .option-item {
                         &.variable-trigger-conversion {
                              .block-tooltip {
                                   top: 0px;
                                   padding: 0;

                                   svg {
                                        top: -6px;
                                        transform: rotate(-90deg) scaleY(-1);
                                   }

                                   .block-tooltip-text {
                                        p {
                                             margin-bottom: 0;
                                             font-size: 15px;
                                             font-weight: 400;
                                             line-height: 20px;
                                             letter-spacing: 0.08px;
                                             word-break: break-word;
                                        }

                                        button {
                                             line-height: 13px;
                                             padding: 0.125rem 0.4375rem;
                                             background-color: #fff;
                                             color: #20a8d8;
                                        }
                                   }
                              }
                         }
                    }
               }

               .option-custom {
                    &.variable-trigger-conversion {
                         .icon-trigger-lookup,
                         .icon-trigger {
                              position: relative;
                              top: 2px;
                         }
                    }
               }
          }
          //----------------------------------------------------------------//
          .details-conversion-row {
               margin-bottom: 15px;

               &.details-conversion-row-end {
                    margin-bottom: 10px;
               }
          }

          .learn-how-to-write {
               padding: 0 15px 3px 0;
               text-decoration: underline;
               text-transform: none;
               position: relative;
               color: #7f8c9a;

               &::after {
                    content: '';
                    position: absolute;
                    top: calc(50% - 1px);
                    right: -10px;
                    width: 6px;
                    height: 6px;
                    transform: translateY(-50%) rotate(45deg);
                    border: 2px solid #7f8c9a;
                    border-left: none;
                    border-bottom: none;
                    margin-top: 2px;
               }
          }

          .destination-conversions {
               .row-rule {
                    padding: 4px 0px 0px;
               }

               .details-conversion {
                    .details-conversion-row {
                         margin-bottom: 15px;

                         &:last-child {
                              margin-bottom: 10px;
                         }
                    }

                    .row {
                         margin-left: -30px;
                    }

                    p {
                         margin-bottom: 10px;
                    }

                    .btn-add-row {
                         margin-left: -15px;
                    }
               }

               .description {
                    // max-width: 80%;
                    font-size: 12px;
               }

               .parameter-checkbox + .details-conversion {
                    .insert-variable-dropdown ul {
                         // min-width: 290px;
                         border-radius: 12px;
                    }
               }
          }

          .ecommerce {
               .row-rule .select-react-customize {
                    color: #2f353a;
               }
          }

          &.accordion-item-rule {
               overflow: unset;
               background-color: #f0f3f5;
               border: none;

               ul {
                    padding-left: 0;
                    margin: 0;
                    display: flex;
                    flex-wrap: wrap;
                    list-style-type: none;
                    margin-left: -5px;
                    margin-right: -5px;

                    li {
                         flex: 0 0 50%;
                         font-weight: 500;
                         padding: 5px;

                         p {
                              white-space: nowrap;
                              overflow: hidden;
                              text-overflow: ellipsis;
                              max-width: 90%;
                         }

                         a {
                              color: #a3afb8;
                         }

                         .content {
                              position: relative;
                              display: inline-flex;
                              align-items: center;
                              justify-content: space-between;
                              height: 35px;
                              width: 100%;
                              font-weight: 700;
                              padding: 10px 15px;
                              background-color: #fff;
                              border-radius: 8px;
                              max-width: 445px;
                         }

                         .block-tooltip {
                              top: 9px;
                              min-width: 235px;

                              svg {
                                   left: -10px;
                              }

                              .block-tooltip-text {
                                   padding: 0.6375rem 0.7625rem;
                              }
                         }
                    }
               }

               .accordion-body {
                    border-top: none;
               }

               .collapse {
                    overflow: hidden;

                    .option-item {
                         white-space: nowrap;

                         .block-tooltip {
                              top: -10px;
                              left: auto;
                              right: 0;
                              transform: translatey(-100%);
                              min-width: inherit;
                              background-color: #18222b;
                              border-radius: 12px;

                              svg {
                                   transform: rotate(-90deg) scaleY(-1);
                                   left: auto;
                                   right: 24px;
                                   top: auto;
                                   bottom: -14px;
                              }

                              .block-tooltip-text {
                                   max-height: inherit;

                                   p {
                                        max-width: inherit;
                                   }
                              }
                         }
                    }
               }
          }

          &.show {
               overflow: inherit;
               margin-bottom: 22px;
               box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);

               .accordion-header {
                    &.accordion-rule {
                         border-bottom: none;
                    }

                    &::after {
                         transform: translateY(-25%) rotate(225deg);
                    }
               }
          }

          &.disabled {
               opacity: 0.5;
               pointer-events: none;

               .accordion-header:after {
                    display: none;
               }
          }

          p {
               padding-left: 0;
               font-family: Roboto;
               font-size: 15px;
               font-weight: 400;
               line-height: 20px;
               letter-spacing: 0.1px;
          }

          h5 {
               font-family: Roboto;
               font-size: 15px;
               font-weight: 600;
               line-height: 22px;
          }

          label {
               color: #73818f !important;
               font-family: Roboto;
               font-size: 14px;
               font-weight: 400;
               line-height: 17px;
               letter-spacing: 0.5px;
          }

          input,
          select {
               &.form-control {
                    min-height: 37px;
               }
          }

          .accordion-header {
               justify-content: flex-start;
               min-height: 45px;
               color: #000;
               width: 100%;
               border: none;
               border-top-left-radius: 12px;
               border-top-right-radius: 12px;
               display: flex;
               align-items: center;
               padding: 12px 20px;
               font-size: 18px;
               line-height: 1.2em;
               position: relative;
               background-color: #f0f3f5;

               &.consent {
                    padding-right: 40px;

                    .title {
                         padding: 0;
                         min-width: 330px;
                    }
               }

               &:after {
                    content: '';
                    position: absolute;
                    top: 22px;
                    right: 22px;
                    width: 7px;
                    height: 7px;
                    transform: translateY(-50%) rotate(45deg);
                    border: 2px solid #000;
                    border-top: none;
                    border-left: none;
                    transition: transform 0.3s;
               }

               p {
                    margin-bottom: 0;
               }

               .title {
                    font-family: 'Roboto';
                    min-width: 285px;
                    padding-right: 50px;
                    text-align: left;
                    color: #18222b;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.26666668px;
                    font-weight: 500;
               }

               .description {
                    font-size: 13px;
                    font-weight: 300;
                    line-height: 20px;
                    letter-spacing: 0.086666666px;
                    text-align: left;
                    padding-right: 20px;
               }
          }

          .accordion-body {
               padding: 19px 20px 24px 20px;
               z-index: 9;
               text-align: left;
               border-top: 1px solid #e2e6e9;

               a.option {
                    &:hover {
                         text-decoration: none;
                    }
               }

               .block-tooltip-text {
                    p {
                         color: #fff;
                    }
               }

               h4 {
                    font-family: Poppins;
                    font-size: 15px;
                    font-weight: 600;
                    line-height: 22px;
               }

               .destination-conversions {
                    .description {
                         // max-width: 80%;
                         font-size: 13px;

                         a {
                              font-weight: 500;
                              text-decoration: underline;
                         }
                    }

                    p {
                         // color: #18222b;
                         font-family: Roboto;
                         font-size: 14px;
                         line-height: 17px;
                         letter-spacing: 0.5px;

                         &.description {
                              font-size: 12px;
                              font-weight: 300;
                              letter-spacing: 0.08px;
                              margin-bottom: 0;
                         }
                    }

                    .manage-conversions {
                         font-weight: 500;
                         margin-bottom: 5px;
                         color: #212c36;
                    }

                    .type-conversions {
                         font-weight: 500;
                         margin-bottom: 11px;
                         margin-top: 20px;
                         color: #212c36;
                    }

                    .form-group {
                         position: relative;

                         &.custom-script-container {
                              .input-icons-custom {
                                   position: relative;
                                   min-width: 106px;

                                   .button-insert-variable {
                                        label {
                                             margin-top: 0px !important;
                                        }
                                   }

                                   button {
                                        position: absolute;
                                        bottom: 0;
                                        left: -20px;
                                   }

                                   svg {
                                        left: 0;
                                        top: 44%;
                                        width: 14px;
                                        height: 14px;
                                        margin: 0;
                                   }

                                   label {
                                        text-decoration: underline;
                                        color: #73818f;
                                        margin-bottom: 0;
                                   }

                                   .insert-variable-dropdown {
                                        min-width: 309px;
                                        right: 0;
                                        left: auto;
                                        top: 10px;

                                        ul {
                                             padding: 0;
                                             overflow-x: hidden;
                                             position: initial;
                                             width: 100%;
                                             border: none !important;
                                             margin-bottom: 0;
                                             border-radius: 12px;
                                             box-shadow: none !important;
                                             max-height: 280px;
                                             margin-top: 0;

                                             &.have-scroll {
                                                  width: calc(100% - 5px);

                                                  li {
                                                       padding-right: 9px;
                                                  }
                                             }

                                             &.script-custom-variable {
                                                  min-width: auto;
                                             }
                                        }
                                   }

                                   .type-variable {
                                        svg {
                                             top: 50%;
                                             transform: translateY(-50%);
                                             left: auto;
                                        }

                                        strong {
                                             margin-right: 0px;
                                        }
                                   }

                                   .button-insert-variable {
                                        label {
                                             margin-left: 5px;
                                             margin-bottom: 11px;
                                             text-decoration: none;
                                             cursor: pointer;

                                             &:hover {
                                                  text-decoration: underline;
                                             }
                                        }
                                   }
                              }
                         }

                         .form-control {
                              padding: 6px 20px 6px 8px;
                              border-radius: 12px;
                              border: 1px solid #e2e6e9 !important;

                              &.form-control[type='radio'] {
                                   border: none !important;
                                   background-color: transparent;
                              }

                              &.is-invalid {
                                   border-color: #f86c6b !important;
                                   padding-right: 46px;
                              }

                              &:disabled {
                                   background-image: url(/assets/icons/lock-dropdown.svg);
                                   background-size: 12px 13px;
                                   background-color: #e4e7ea;
                                   background-repeat: no-repeat;
                                   background-position: center right 20px;
                              }
                         }
                    }

                    .purchase-google-ads {
                         display: flex;
                         align-items: center;
                         padding: 10px 0;
                    }

                    .tooltip-wrapper {
                         &.tooltip-purchase {
                              .block-tooltip {
                                   top: -7px;
                                   left: 50%;
                                   transform: translate(-50%, -100%);
                                   /* min-width: inherit; */
                                   background-color: #18222b;
                                   border-radius: 12px;

                                   svg {
                                        width: 42px;
                                        bottom: 7px;
                                        transform: translate(-50%, 100%) rotate(263deg);
                                        color: #18222b;
                                        top: 82%;
                                        left: 52%;
                                   }

                                   .block-tooltip-text {
                                        border-top-left-radius: 12px;
                                   }
                              }

                              .block-tooltip-text {
                                   border-top-left-radius: 12px;
                              }

                              svg {
                                   top: 1px;
                              }
                         }

                         .hover-show-tooltip {
                              position: relative;
                         }
                    }

                    .row-rule {
                         .tooltip-wrapper {
                              &.tooltip-event {
                                   position: absolute;
                                   right: 13px;
                                   top: 50%;
                                   transform: translateY(-50%);

                                   .block-tooltip {
                                        transform: translateX(-75%);
                                        top: -85px;

                                        svg {
                                             width: 42px;
                                             top: 55px;
                                             bottom: auto;
                                             left: 70%;
                                             transform: translate(-50%, 100%) rotate(-90deg);
                                             color: #18222b;
                                        }
                                   }

                                   &.tooltip-invalid {
                                        top: 26px;
                                   }
                              }
                         }
                    }

                    .details-conversion {
                         position: relative;

                         label {
                              margin-right: 16px;

                              &::after {
                                   content: '';
                                   position: absolute;
                                   top: 47%;
                                   right: 15px;
                                   width: 7px;
                                   height: 7px;
                                   transform: translateY(-50%) rotate(45deg);
                                   border: 2px solid #000;
                                   border-top: none;
                                   border-left: none;
                                   transition: transform 0.3s;
                              }

                              &.show {
                                   &::after {
                                        top: 54%;
                                        transform: translateY(-50%) rotate(225deg);
                                   }
                              }

                              &.add-row {
                                   &::after {
                                        content: none;
                                   }
                              }
                         }
                    }

                    .row-rule {
                         min-height: 46px;
                         padding-top: 0;

                         .tooltip-wrapper {
                              &:hover {
                                   position: relative;
                                   z-index: 9;
                              }
                         }

                         .consider-conversion {
                              top: 3px;
                         }
                    }

                    .options-data-wrapper {
                         p {
                              margin-bottom: 0;
                         }
                    }

                    a.option {
                         &:hover {
                              text-decoration: none;
                         }
                    }

                    .input-icons {
                         position: relative;

                         .icon:not(.icon-c-s):not(.icon-custom-size) {
                              height: 13px;
                              width: 13px;
                         }

                         svg {
                              position: absolute;
                              right: 12px;
                              top: 50%;
                              transform: translateY(-50%);
                         }

                         .value-no-options-available {
                              svg {
                                   position: relative;
                                   top: 50%;
                                   right: 5px;
                              }
                         }

                         .form-control {
                              &.input-content {
                                   &.is-invalid {
                                        background-position: right calc(2em + 0.1875rem) center;
                                   }
                              }
                         }

                         &.input-icons-errors {
                              .form-control.is-invalid {
                                   background-position: right calc(2em + 0.1875rem) center;
                                   padding-right: calc(2.6em + 0.75rem);
                              }

                              &.input-icons-field {
                                   svg {
                                        top: 50%;
                                   }
                              }

                              .value-no-options-available {
                                   svg {
                                        top: 50%;
                                   }
                              }
                         }

                         li {
                              min-height: 38px;

                              &.variable-filter-bottom {
                                   height: 0px;
                                   min-height: auto;
                              }
                         }

                         .type-variable {
                              position: relative;
                              padding: 0;

                              strong {
                                   word-break: break-word;

                                   &:nth-of-type(2) {
                                        white-space: nowrap;
                                   }
                              }

                              svg {
                                   right: 15px;
                              }

                              svg {
                                   &.icon:not(.icon-c-s):not(.icon-custom-size) {
                                        height: 8px;
                                        width: 8px;
                                   }
                              }
                         }

                         .insert-variable-dropdown {
                              margin-top: -1px;
                              list-style: none;
                              position: absolute;
                              left: 0;
                              padding: 0px;
                              z-index: 99;
                              border: 1px solid #e4e7ea !important;
                              box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.13) !important;
                              max-height: 280px;
                              background-color: #fff;
                              width: 100%;
                              border-radius: 12px;
                              overflow: hidden;
                              margin-bottom: 1rem;
                         }

                         ul {
                              padding: 0;
                              overflow-x: hidden;
                              position: initial;
                              width: 100%;
                              border: none !important;
                              margin-bottom: 0;
                              border-radius: 12px;
                              box-shadow: none !important;
                              max-height: 280px;
                              margin-top: 0;

                              &.have-scroll {
                                   width: calc(100% - 5px);

                                   li {
                                        padding-right: 9px;

                                        &.variable-filter {
                                             padding-right: 9px;
                                        }
                                   }
                              }

                              &::-webkit-scrollbar {
                                   width: 3px;
                              }

                              &::-webkit-scrollbar-track {
                                   width: 3px;
                                   background-color: #e2e6e9;
                                   border-radius: 2px;
                                   margin-top: 8px;
                                   margin-bottom: 8px;
                              }

                              &::-webkit-scrollbar-thumb {
                                   background: #73818f;
                                   width: 3px;
                                   border-radius: 12px;
                                   border: none;
                                   min-height: 50px;
                              }

                              li {
                                   padding: 5px 17px 5px 17px;

                                   &:hover {
                                        background: #f3f5f6;

                                        span {
                                             color: #20a8d8 !important;
                                        }
                                   }

                                   &.variable-filter {
                                        background: #fff;
                                        z-index: 10;
                                        padding: 10px 17px 10px 17px;
                                   }
                              }
                         }

                         .insert-variable-dropdown ul {
                              padding: 0;

                              li {
                                   &.border-bottom {
                                        padding: 5px 12px 5px 17px;
                                   }

                                   .form-control {
                                        min-width: auto;
                                   }
                              }
                         }
                    }

                    .parameter-field {
                         .include-data-tooltip {
                              input {
                                   z-index: 10;
                              }

                              label {
                                   cursor: pointer;
                              }
                         }

                         .browser-step4-form {
                              svg {
                                   width: auto;
                                   height: 14px;
                              }
                         }
                    }

                    .select-react-customize {
                         .select-wrapper > input {
                              background: #fff;
                              background-image: url(/assets/icons/icon-select.svg);
                              background-repeat: no-repeat;
                              background-position-x: calc(100% - 9px);
                              background-position-y: center;
                              background-size: 6px;

                              &.is-invalid {
                                   border: 1px solid #f86c6b !important;
                                   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23f86c6b' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f86c6b' stroke='none'/%3e%3c/svg%3e");
                                   background-repeat: no-repeat;
                                   background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
                              }
                         }

                         .select-wrapper > input[disabled] {
                              background: transparent;
                         }
                    }
               }

               .description {
                    p {
                         word-break: break-word;
                         // color: #18222b;
                         font-family: 'Roboto';
                         font-size: 14px;
                         font-weight: 400;
                         line-height: 17px;
                         letter-spacing: 0.5px;

                         code {
                              font-size: 15px;
                         }
                    }
               }
          }

          .drag-drop-guide {
               display: flex;
               align-items: center;
               margin-bottom: 18px;
               color: #121920;
               font-family: Roboto;
               font-size: 16px;
               font-weight: 500;
               line-height: 22px;

               svg {
                    margin-top: -1px;
                    margin-right: 10px;
                    color: #c8ced3;
                    width: 15px;
                    height: 15px;
               }

               strong {
                    font-weight: 500;
                    margin-right: 5px;
               }
          }

          // .info-data-wrap {
          //      width: 45%;
          // }

          // .action-data-wrap {row-rule
          //      width: 35%;
          //      position: relative;
          // }

          .options-data-wrapper {
               width: 65%;

               &.expand {
                    width: 80%;
               }

               .option-item3:not(:first-child):before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    width: 1px;
                    height: 20px;
                    transform: translateY(-50%);
                    background-color: #b5dce9;
               }
          }

          .row-rule {
               .option-item2.option-item {
                    &::before {
                         content: '';
                         display: block;
                         position: absolute;
                         top: 50%;
                         left: 0;
                         width: 2px;
                         height: 20px;
                         transform: translateY(-50%);
                         background-color: #b5dce9;
                    }

                    .block-tooltip .block-tooltip-text p {
                         text-transform: none;
                    }
               }

               .option-custom {
                    color: #a3afb8;

                    &.option-item:hover:not(.switch-item) .option {
                         background-color: transparent;
                         text-decoration: none;
                    }

                    a.option {
                         color: #a3afb8;
                         align-items: center;
                         padding: 13px 14px;
                         font-size: 15px;

                         .error {
                              color: #f86b6c;
                         }
                    }

                    p.template {
                         padding: 15px 14px;
                    }

                    .icon-action {
                         width: 20px;
                         height: 20px;
                         color: #20a8d8;
                    }

                    .select-label {
                         color: #2f353a;
                    }
               }
          }

          .triggers-rules {
               .row-rule {
                    h4 {
                         font-family: Poppins;
                         font-size: 15px;
                         font-weight: 600;
                         line-height: 22px;
                    }
               }

               p {
                    font-family: Roboto;
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0.1px;
               }

               .datalayer-variables {
                    p {
                         font-size: 14px;
                         max-width: 480px;
                    }
               }

               .row-rule {
                    .info-wrap {
                         // min-width: 45%;
                         max-width: 70%;
                    }

                    h4 {
                         // max-width: 80%;
                         padding: 10px 0;
                    }

                    .label-gtm {
                         background-color: #58afe0;
                         font-weight: 500;
                         border-radius: 3.25rem;
                         padding: 0.375rem 0.75rem;
                         font-size: 0.875rem;
                         line-height: 1.5;
                         text-align: center;
                         vertical-align: middle;
                         color: #fff;
                    }

                    .label-gtm-1 {
                         background-color: #2f353a;
                    }
               }
          }

          .row-rule {
               border-radius: 12px;
               background: #f0f3f5;
               padding-left: 12px;
               margin-bottom: 8px;

               .info-wrap {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-right: 15px;
               }

               &.row-rule-trigger {
                    margin-bottom: 0;

                    .info-rule {
                         min-width: 55%;
                    }

                    .accordion-rule {
                         width: auto;
                         justify-content: flex-end;

                         span {
                              margin-right: 22px;
                              color: #708393;
                              font-family: Roboto;
                              font-size: 14px;
                              font-weight: 400;
                              line-height: 17px;
                              text-align: right;
                              letter-spacing: 0.2px;
                         }

                         &:after {
                              content: '';
                              position: absolute;
                              top: 21px;
                              right: 26px;
                              width: 6px;
                              height: 6px;
                              transform: translateY(-50%) rotate(45deg);
                              border: 1px solid #73818f;
                              border-top: none;
                              border-left: none;
                              transition: transform 0.3s;
                         }

                         &.show {
                              &::after {
                                   top: 22px;
                                   transform: translateY(-25%) rotate(225deg);
                              }
                         }
                    }
               }

               &.row-rule-default {
                    h4 {
                         max-width: 80%;
                    }

                    .accordion-rule {
                         background-color: #fff;
                    }

                    // .info-wrap {
                    //      min-width: 30%;
                    // }

                    .option-item .form-group {
                         .partner-input {
                              border: none;
                         }
                    }

                    .options-data-wrapper {
                         width: 70%;
                    }

                    .option-item3,
                    .option-item2 {
                         &::before {
                              content: none;
                         }
                    }

                    .option-item3 {
                         padding: 0 15px;
                         position: relative;

                         input {
                              background-color: #f1f3f5;
                              width: 250px;
                              padding-right: 65px;
                         }

                         button {
                              position: absolute;
                              right: 16px;
                              top: 50%;
                              transform: translateY(-50%);
                              cursor: pointer;
                              font-weight: 500;
                              margin: 0;
                              padding: 8px;

                              &::before {
                                   content: '';
                                   display: block;
                                   position: absolute;
                                   top: 50%;
                                   left: 0;
                                   width: 2px;
                                   height: 20px;
                                   transform: translateY(-50%);
                                   background-color: #b5dce9;
                              }
                         }
                    }
               }

               .icon-grip-lines {
                    width: 12px;
                    height: 6px;
                    margin-right: 12px;
                    min-width: 12px;
               }

               h4 {
                    margin-bottom: 0;
                    font-family: Poppins;
                    font-size: 15px;
                    font-weight: 600;
                    line-height: 22px;
               }

               .tooltip-wrapper {
                    position: relative;
                    padding-bottom: 3px;
                    margin-left: 10px;
                    cursor: pointer;

                    svg {
                         width: 12px;
                         left: -10px;
                    }

                    > svg path {
                         fill: #20a8d8 !important;
                    }
               }

               &.template-conversion .tooltip-wrapper > svg path {
                    fill: #73818f !important;
               }

               .option-item {
                    position: relative;
                    display: flex;
                    margin-left: -1px;
                    cursor: initial;

                    &:before {
                         content: '';
                         display: block;
                         position: absolute;
                         top: 50%;
                         left: 0;
                         width: 1px;
                         height: 20px;
                         transform: translateY(-50%);
                         background-color: #b5dce9;
                    }

                    &:hover:not(.switch-item) .option {
                         background-color: #b5dce9;
                    }

                    &.switch-item {
                         .option {
                              border-top-right-radius: 12px;
                              border-bottom-right-radius: 12px;
                         }
                    }

                    .option {
                         align-items: center;
                    }

                    .block-tooltip {
                         top: 0;
                         left: 50%;
                         transform: translate(-50%, -100%);
                         min-width: inherit;
                         background-color: #18222b;
                         border-radius: 12px;

                         .block-tooltip-text {
                              padding: 6px 8px;
                              text-align: center;
                              white-space: nowrap;
                              border-radius: 5px;

                              p {
                                   font-size: 10px;
                                   font-weight: 500;
                                   line-height: 12px;
                                   letter-spacing: 0.16666667px;
                                   color: #fff;
                              }
                         }

                         .arrow-tooltip-preview {
                              width: 42px;
                              top: auto;
                              bottom: 7px;
                              left: 50%;
                              transform: translate(-50%, 100%) rotate(180deg);
                              color: #18222b;
                         }

                         &.switch-item-disable {
                              top: 0;
                              right: 100%;
                              transform: translate(50%, -100%);
                              left: auto;

                              .arrow-tooltip-preview {
                                   left: 65%;
                              }
                         }
                    }
               }

               .option {
                    position: relative;
                    display: flex;
                    padding: 15px 14px;
                    align-items: center;

                    &-item-delete {
                         padding: 11px 11px;
                         min-height: 46px;
                    }
               }

               .icon-delete {
                    width: 20px;
                    height: auto;
               }

               .switch-item .option {
                    padding: 12px 14px;
               }
          }

          .row-rule-trigger-accordion {
               font-weight: 500;
               padding: 5px 20px 27px 40px;

               .description {
                    margin-bottom: 15px;
               }
          }

          .reset-to-default-box {
               color: #73818f;
               font-size: 13px;
               font-weight: 400;
               line-height: 19px;
               letter-spacing: 0;
               padding-left: 4px;
               margin-top: 14px;

               .description {
                    color: inherit;
                    font-size: inherit;
                    font-weight: inherit;
                    line-height: inherit;
                    letter-spacing: inherit;
               }

               .btn {
                    font-size: inherit;
                    color: inherit;
                    text-transform: inherit;
                    text-decoration: underline;
                    padding: 0;
                    margin-left: 3px;

                    &:hover {
                         text-decoration: none;
                    }
               }
          }

          .list-fields .variable label {
               margin-left: 2px;
          }
     }

     .learn-how-to-write {
          padding: 0 15px 3px 0;
          text-decoration: underline;
          text-transform: none;
          position: relative;
          color: #7f8c9a;

          &::after {
               content: '';
               position: absolute;
               top: calc(50% - 1px);
               right: 0px;
               width: 6px;
               height: 6px;
               transform: translateY(-50%) rotate(45deg);
               border: 2px solid #7f8c9a;
               border-left: none;
               border-bottom: none;
               margin-top: 2px;
          }
     }

     .learn-how-to-write {
          padding-right: 15px;
          margin-left: 9px;
     }

     .default-rules {
          .headline .tooltip-wrapper {
               margin-left: 7px;
          }
     }

     .default-rules {
          background-color: #f0f3f5;
          padding: 20px 20px 14px;
          margin: 20px -20px;

          .headline {
               display: flex;
               align-items: center;
               margin-bottom: 8px;

               .tooltip-wrapper {
                    position: relative;
                    padding-bottom: 3px;
                    margin-left: 5px;
                    cursor: pointer;

                    > svg {
                         height: 12px;

                         path {
                              fill: #73818f !important;
                         }
                    }
               }
          }

          h5 {
               color: #18222b;
               font-size: 16px;
               font-weight: 500;
               line-height: 24px;
               letter-spacing: 0.26666668px;
               margin-bottom: 0;
          }

          .row-rule {
               background-color: #fff;
               padding: 0 15px;

               h4 {
                    margin: 0;
               }

               .option-item:last-child:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 50%;
                    right: 0;
                    width: 1px;
                    height: 20px;
                    transform: translateY(-50%);
                    background-color: #b5dce9;
               }
          }
     }

     .default-rules {
          .accordion-body {
               background-color: #fff;
          }

          .accordion-item-rule {
               background-color: #fff;

               .collapse {
                    overflow: inherit;
               }
          }

          .row-rule {
               .option-item {
                    &.status-default-rule {
                         .block-tooltip {
                              .block-tooltip-text {
                                   span {
                                        width: 300px;
                                        white-space: initial;
                                        display: block;
                                   }
                              }
                         }
                    }
               }

               .option-item:last-child {
                    &:after {
                         content: none;
                    }
               }
          }

          .row-rule-trigger-accordion {
               border-radius: 12px;

               .content {
                    background-color: #f0f3f5;

                    .tooltip-wrapper {
                         position: relative;
                         margin-left: 10px;

                         .block-tooltip {
                              min-width: 303px;
                         }

                         p {
                              white-space: unset;
                              max-width: none;
                         }
                    }
               }
          }

          .fix-Width {
               max-width: none !important;
          }
     }
}

.k-chart-shared-tooltip {
     &.k-chart-tooltip {
          position: relative;
          &::before {
               content: '';
               position: absolute;
               top: 0;
               right: -8px;
               width: 150px;
               height: 45px;
               z-index: -1;
          }
     }
}
