h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
	line-height: var(--cui-heading-line-height);
}

.caption {
	font-size: 12px;
	line-height: 16px;
}

.caption-sm {
	font-size: 10px;
	line-height: 14px;
}

.body-sm{
	font-size: 13px;
	line-height: 18px;
}

.body {
	font-size: 14px;
	line-height: 20px;
}

.overline {
	font-size: 10px;
	line-height: 14px;
	letter-spacing: 0.04em;
	text-transform: uppercase;
}

.bold {
	font-weight: 700;
}

.semibold {
	font-weight: 600;
}

.medium {
	font-weight: 500;
}

.regular {
	font-weight: 400;
}

//===================Text Color=================================
.warning {
	color: $warning;
}

.secondary {
	color: $secondary;
}

.gray-600{
	color: $gray-600;
}

.text-body {
	color: $body-colors !important;
}

.text-dark {
	color: #212C36 !important;
}

.title-color {
	color:  #1E293B !important;
}

.text-color {
	color: #475569 !important;
}

.des-color {
	color: #424242;
}

.text-gray-500 {
	color: #4B5563;
}
//===================BackGround Color===========================
.bg-warning {
	background-color: #FFC107 !important;
	border: 0.5px solid #B38600 !important;
}

.bg-gray-600 {
	background-color: #D1D5DB !important;
	border:  0.5px solid #6B7280 !important;
}
//===================Margin=====================================
.mb-3 {
	margin-bottom: 6px !important;
}

.mb-6 {
	margin-bottom: 12px !important;
}

.mt-8 {
	margin-top: 16px;
}

.mb-8 {
	margin-bottom: 16px;
}

.ml-12 {
	margin-left: 24px;
}

.ml-8 {
	margin-left: 16px;
}
//======================Border======================================

.border-bot{
	border-bottom: 1px solid #f5f5f5;
}