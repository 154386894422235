input,
input:focus,
input:active,
button,
button:focus,
button:active,
.bootstrap-select .btn:focus,
option,
textarea {
     outline: none !important;
     outline: 0 !important;
     box-shadow: none !important;
     box-shadow: 0 !important;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
     font-weight: 500;
}

h1,
.h1 {
     font-weight: 300;
}

h2,
.h2 {
     font-weight: 300;
}

a {
     &:not([href]):not([tabindex]),
     &:not([href]):not([tabindex]):hover,
     &:hover {
          &.header-nav-link {
               color: #708393;
               font-weight: 400;
          }
          color: $primary;
     }

     &.text-primary:focus,
     &.text-primary:hover {
          &.option {
               color: #20a8d8 !important;
          }
          color: #167495 !important;
     }
}

strong,
b {
     font-weight: 500;
}

p {
     font-weight: 300;
     font-size: rem($font-b);
}

.p-13 {
     p {
          font-size: rem(13);
     }
}

.p-14 {
     p {
          font-size: rem(14);
     }
}

.relative {
     position: relative !important;
}

.visible-hidden {
     visibility: hidden;
}

.font-weight-400 {
     font-weight: 400 !important;
}

.no-scroll {
     overflow: hidden;
}

.scroll-y {
     overflow-y: scroll;
}

.progress-success {
     .progress-bar {
          background-color: $success;
     }
}

.progress-warning {
     .progress-bar {
          background-color: $warning;
     }
}

.progress-danger {
     .progress-bar {
          background-color: $danger;
     }
}

.progress-inactive {
     .progress-bar {
          position: relative;
          left: -1px;
     }
}

.get-help {
     position: fixed;
     bottom: 0;
     right: 1.9rem;
     border-radius: 0.25rem 0.25rem 0 0;
     height: 35px;
     width: 121px;
     display: flex;
     align-items: center;
     padding: 0 1.1rem;
     z-index: 9999;
     cursor: pointer;
     right: 24px;
     bottom: 24px;
     border-radius: 25px;
     box-shadow:
          0px 4px 6px -2px rgba(0, 0, 0, 0.05),
          0px 10px 15px -3px rgba(0, 0, 0, 0.1);

     svg {
          margin-right: 0.5rem;
     }

     button {
          text-transform: uppercase;
          box-shadow: none !important;
          font-weight: 500;
          padding: 0;
     }

     &.blue {
          background-color: $blue;
          color: #fff !important;

          button {
               color: #fff !important;
          }
     }

     &.yellow {
          background-color: $yellow;
          color: #000 !important;

          button {
               color: #000 !important;
               font-weight: 700;
               // width: 62px;
               height: 16px;
               font-family: Roboto;
               font-size: 13px;
               line-height: 16px;
               text-align: center;
               text-transform: uppercase;
               letter-spacing: 0.18571427px;
          }
     }
}

.box-preview {
     position: fixed;
     height: 35px;
     width: auto;
     display: flex;
     align-items: center;
     padding: 0 2.5rem 0 1.2rem !important;
     z-index: 9999;
     cursor: pointer;
     right: 165px;
     bottom: 24px;
     border-radius: 25px;
     box-shadow:
          0px 4px 6px -2px rgba(0, 0, 0, 0.05),
          0px 10px 15px -3px rgba(0, 0, 0, 0.1);
     background-color: #18222b;
     color: #fff;

     &.btn-header {
          position: relative;
          z-index: 1;
          bottom: 0;
          right: 0;
          margin-left: 30px;
          padding-top: 2px;
     }

     &:hover {
          background-color: #2d3740;
          color: #fff;

          .pulse-status.online {
               animation: none;
          }
     }

     .pulse-status {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 12px;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: rgb(99, 99, 99);

          &.online {
               background-color: #4dbd74;
               box-shadow: 0 0 0 rgba(153, 241, 145, 0.4);
               animation: pulse-status 1s infinite;
          }
     }

     @-webkit-keyframes pulse-status {
          0% {
               -webkit-box-shadow: 0 0 0 0 rgba(153, 241, 145, 0.4);
          }

          70% {
               -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
          }

          100% {
               -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
          }
     }

     @keyframes pulse-status {
          0% {
               -moz-box-shadow: 0 0 0 0 rgba(153, 241, 145, 0.4);
               box-shadow: 0 0 0 0 rgba(153, 241, 145, 0.4);
          }

          70% {
               -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
               box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
          }

          100% {
               -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
               box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
          }
     }
}

.text-underline {
     text-decoration: underline !important;

     &:hover {
          text-decoration: none !important;
     }
}

.label-current {
     color: #20a8d8;
     background-color: #f0f3f5;
     // padding: 3px 6px;
     padding: 4px 6px 4px 6px;
     border-radius: 12px;
}

.label-share {
     padding: 3px 6px;
     background-color: $warning;
     color: #18222b;
     font-size: 9px;
}

.w-80 {
     width: 80%;
}

.fw-5 {
     font-weight: 500 !important;
}

.fw-4 {
     font-weight: 400 !important;
}

.f-10 {
     font-size: 10px !important;
}

.f-12 {
     font-size: 12px !important;
}

.f-13 {
     font-size: 13px !important;
}

.f-14 {
     font-size: 14px !important;
}

.f-15 {
     font-size: 15px !important;
}

.icon-info {
     cursor: pointer;
}

.opacity-70 {
     opacity: 0.7 !important;
}

.opacity-100 {
     opacity: 1 !important;
}

textarea {
     resize: none;

     &.resizable {
          resize: auto;
     }
}

.baseline {
     align-items: baseline;
}

.margin-bottom-5 {
     margin-bottom: 5px;
}

.d-none {
     display: none !important;
}

.message-warning {
     background-color: #fee1e1;
     padding: 15px;
     margin-bottom: 20px;
     color: #863f3f;
     border-radius: 5px;
     position: relative;

     span {
          @include position-over-right-middle(-35px);
          cursor: pointer;
          font-weight: 500;
     }
}

.bg-gray-600 {
     background-color: $gray-600;
}

.icon-cil-ban {
     color: $danger !important;
}

.no-filtering-result {
     margin-top: 62px;

     .icon-cil-ban {
          margin-left: 10px;
     }
}

.hover-show-tooltip {
     ~ .block-tooltip-bottom-left {
          display: none;
     }

     &:hover {
          ~ .block-tooltip,
          .block-tooltip {
               display: block;
               z-index: 100;
          }
          ~ .block-tooltip-bottom-left,
          .block-tooltip-bottom-left {
               display: block;
               position: absolute;
               top: 25px;
               right: 2px;
               min-width: 245px;
               transition: 0.3s all linear;
               z-index: 100;
               svg {
                    position: absolute;
                    height: 20px !important;
                    right: 2px;
                    top: -11px;
                    rotate: 90deg;
               }
               .block-tooltip-text {
                    color: #fff;
                    background-color: #19222b;
                    padding: 0.9375rem 1.0625rem;
                    border-radius: 12px;
                    border-top-right-radius: 0;
                    box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.13);
                    p {
                         color: #fff;
                         margin: 0;
                    }
               }
          }

          ~ .block-tooltip-top-center,
          .block-tooltip-top-center {
               display: block;
               position: absolute;
               top: -63px;
               left: 8px;
               min-width: 245px;
               transition: 0.3s all linear;
               z-index: 2000;
               svg {
                    position: absolute;
                    height: 20px !important;
                    top: 46px;
                    left: 2px;
                    rotate: -90deg;
               }
               .block-tooltip-text {
                    color: #fff;
                    background-color: #19222b;
                    padding: 0.9375rem 1.0625rem;
                    border-radius: 12px;
                    border-bottom-left-radius: 0;
                    box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.13);
                    p {
                         color: #fff;
                         margin: 0;
                    }
               }
          }

          ~ .block-tooltip-bottom-center,
          .block-tooltip-bottom-center {
               display: block;
               position: absolute;
               top: 30px;
               min-width: 245px;
               transition: 0.3s all linear;
               z-index: 2000;
               left: 50%;
               transform: translate(-50%);
               svg {
                    position: absolute;
                    height: 20px !important;
                    top: -13px;
                    left: 45%;
                    rotate: 90deg;
               }
               .block-tooltip-text {
                    color: #fff;
                    background-color: #19222b;
                    padding: 0.9375rem 1.0625rem;
                    border-radius: 12px;
                    box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.13);
                    p {
                         color: #fff;
                         margin: 0;
                    }
               }
          }

          ~ .block-tooltip-bottom-right,
          .block-tooltip-bottom-right {
               display: block;
               position: absolute;
               top: 30px;
               left: -228px;
               min-width: 245px;
               transition: 0.3s all linear;
               z-index: 2000;
               svg {
                    position: absolute;
                    height: 20px !important;
                    top: -14px;
                    left: 227px;
                    rotate: 90deg;
               }
               .block-tooltip-text {
                    color: #fff;
                    background-color: #19222b;
                    padding: 0.9375rem 1.0625rem;
                    border-radius: 12px;
                    border-top-right-radius: 0;
                    box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.13);
                    p {
                         color: #fff;
                         margin: 0;
                    }
               }
          }
     }
}

.custom-report {
     .hover-show-tooltip {
          &:hover {
               ~ .block-tooltip-bottom-left,
               .block-tooltip-bottom-left {
                    min-width: 312px;
               }
          }
     }
}

.icon-tooltip-block {
     display: inline-block;
     position: relative;
}

.block-tooltip-bottom {
     &.rule-tooltips,
     &.type-click {
          display: block;
     }

     position: absolute;
     top: 29px;
     right: -115px;
     z-index: 1;
     min-width: 310px;
     display: none;
     transition: 0.3s all linear;

     &.reporting-values-tooltip {
          display: block;
          top: auto;
          bottom: 42px;
          left: -160px;

          .block-tooltip-text {
               p {
                    margin-bottom: 5px;
               }
          }

          svg {
               left: calc(50% + 20px);
               top: auto;
               bottom: -12px;
               transform: rotate(-90deg);
          }
     }

     svg {
          position: absolute;
          height: 20px !important;
          left: 168px;
          top: -13px;
          rotate: 81deg;
     }

     .block-tooltip-text {
          color: $white;
          background-color: #19222b;
          padding: rem(15) rem(17);
          border-radius: 12px;
          box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.13);

          &::-webkit-scrollbar {
               width: 3px;
               border-radius: 10px;
          }

          &::-webkit-scrollbar-track {
               background: #19222b;
               border-radius: 10px;
          }

          &::-webkit-scrollbar-thumb {
               background: #f1f1f1;
               border-radius: 10px;
          }

          p {
               font-size: 15px;
               color: inherit;
               font-weight: 400;
               letter-spacing: 0.1px;
               line-height: 20px;

               &:last-child {
                    margin-bottom: 0;
               }
          }
     }
}

.person-revealed {
     .block-tooltip {
          .block-tooltip-text {
               border-top-left-radius: 12px;
          }
     }
}

.block-tooltip {
     &.rule-tooltips,
     &.type-click {
          display: block;
     }

     position: absolute;
     top: 7px;
     left: calc(100% + 15px);
     z-index: 1;
     min-width: 303px;
     display: none;
     transition: 0.3s all linear;

     &.reporting-values-tooltip {
          display: block;
          top: auto;
          bottom: 42px;
          left: -160px;

          .block-tooltip-text {
               p {
                    margin-bottom: 5px;
               }
          }

          svg {
               left: calc(50% + 20px);
               top: auto;
               bottom: -12px;
               transform: rotate(-90deg);
          }
     }

     svg {
          position: absolute;
          height: 20px !important;
          left: -11px;
          top: 0;
     }

     .block-tooltip-text {
          color: $white;
          background-color: #19222b;
          padding: rem(15) rem(17);
          border-radius: 12px;
          border-top-left-radius: 0;
          box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.13);
          // overflow: auto;

          &::-webkit-scrollbar {
               width: 3px;
               border-radius: 10px;
          }

          &::-webkit-scrollbar-track {
               background: #19222b;
               border-radius: 10px;
          }

          &::-webkit-scrollbar-thumb {
               background: #f1f1f1;
               border-radius: 10px;
          }

          p {
               font-size: 14px;
               color: #fff !important;
               font-weight: 400;

               &:last-child {
                    margin-bottom: 0;
               }
          }
     }
}

.block-tooltip-top-center {
     &.rule-tooltips,
     &.type-click {
          display: block;
     }

     position: absolute;
     top: 7px;
     left: calc(100% + 15px);
     z-index: 1;
     min-width: 303px;
     display: none;
     transition: 0.3s all linear;

     &.reporting-values-tooltip {
          display: block;
          top: auto;
          bottom: 42px;
          left: -160px;

          .block-tooltip-text {
               p {
                    margin-bottom: 5px;
               }
          }

          svg {
               left: calc(50% + 20px);
               top: auto;
               bottom: -12px;
               transform: rotate(-90deg);
          }
     }

     svg {
          position: absolute;
          height: 20px !important;
          left: -11px;
          top: 0;
     }

     .block-tooltip-text {
          color: $white;
          background-color: #19222b;
          padding: rem(15) rem(17);
          border-radius: 12px;
          border-bottom-left-radius: 0;
          box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.13);
          // overflow: auto;

          &::-webkit-scrollbar {
               width: 3px;
               border-radius: 10px;
          }

          &::-webkit-scrollbar-track {
               background: #19222b;
               border-radius: 10px;
          }

          &::-webkit-scrollbar-thumb {
               background: #f1f1f1;
               border-radius: 10px;
          }

          p {
               font-size: 14px;
               color: #fff !important;
               font-weight: 400;

               &:last-child {
                    margin-bottom: 0;
               }
          }
     }
}

.block-tooltip-bottom-right,
.block-tooltip-bottom-center {
     &.rule-tooltips,
     &.type-click {
          display: block;
     }

     position: absolute;
     top: 7px;
     left: calc(100% + 15px);
     z-index: 1;
     min-width: 303px;
     display: none;
     transition: 0.3s all linear;

     &.reporting-values-tooltip {
          display: block;
          top: auto;
          bottom: 42px;
          left: -160px;

          .block-tooltip-text {
               p {
                    margin-bottom: 5px;
               }
          }

          svg {
               left: calc(50% + 20px);
               top: auto;
               bottom: -12px;
               transform: rotate(90deg);
          }
     }

     svg {
          position: absolute;
          height: 20px !important;
          left: -11px;
          top: 0;
     }

     .block-tooltip-text {
          color: $white;
          background-color: #19222b;
          padding: rem(15) rem(17);
          border-radius: 12px;
          border-top-right-radius: 0;
          box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.13);
          // overflow: auto;

          &::-webkit-scrollbar {
               width: 3px;
               border-radius: 10px;
          }

          &::-webkit-scrollbar-track {
               background: #19222b;
               border-radius: 10px;
          }

          &::-webkit-scrollbar-thumb {
               background: #f1f1f1;
               border-radius: 10px;
          }

          p {
               font-size: 14px;
               color: #fff !important;
               font-weight: 400;

               &:last-child {
                    margin-bottom: 0;
               }
          }
     }
}

.icon-hubspot {
     min-width: 20px;
     position: relative;
     top: 3px;
}

.features .title .icon-hubspot {
     top: 6px;
}

.icon-tag-manager {
     min-width: 25px;
}

.ace_scrollbar {
     &.ace_scrollbar-v {
          width: 6px !important;
          height: 100%;

          &::-webkit-scrollbar {
               width: 6px;
               background-color: $gray-200;
          }

          &::-webkit-scrollbar-thumb {
               background-color: $gray-300;
          }
     }

     &.ace_scrollbar-h {
          height: 6px !important;
          width: calc(100% - 47px);

          &::-webkit-scrollbar {
               height: 6px;
               background-color: $gray-200;
          }

          &::-webkit-scrollbar-thumb {
               background-color: $gray-300;
          }
     }
}

.hidden {
     display: none !important;
}

#category {
     .react-select__menu {
          z-index: 1000;
     }
}

.text-transform-none {
     text-transform: none !important;
}

.page-loading-container {
     // position: absolute;
     position: fixed;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
}

.features,
.events-and-variables,
.listener-reporting,
.selector-rules {
     .description-wrapper {
          li {
               font-weight: 300;
               font-size: rem($font-b);
          }

          a {
               text-decoration: underline !important;

               &:hover {
                    text-decoration: none !important;
                    color: #167495 !important;
               }
          }
     }
}

.c-wrapper.v2 {
     .description-wrapper p {
          font-weight: 400;
     }

     a {
          &:hover {
               text-decoration: none;
          }
     }
}

.is-invalid {
     &.custom-select-invalid {
          border-color: #f86c6b !important;

          &.select-react-customize .select-wrapper .form-control {
               border-color: #f86c6b !important;
          }
     }

     .sun-editor {
          border: 2px solid #f86c6b;

          .se-toolbar {
               outline: 2px solid #f86c6b;
          }

          .se-wrapper {
               .se-wrapper-wysiwyg {
                    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23f86c6b' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f86c6b' stroke='none'/%3e%3c/svg%3e");
                    background-repeat: no-repeat;
                    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
                    background-position: right 10px bottom 10px;
               }
          }
     }
}

@media (min-width: 1400px) {
     .col-xxl-auto {
          flex: 0 0 auto;
          max-width: auto;
     }

     .col-xxl-1 {
          flex: 0 0 auto;
          max-width: 8.33333333%;
     }

     .col-xxl-2 {
          flex: 0 0 auto;
          max-width: 16.66666667%;
     }

     .col-xxl-3 {
          flex: 0 0 auto;
          max-width: 25%;
     }

     .col-xxl-4 {
          flex: 0 0 auto;
          max-width: 33.33333333%;
     }

     .col-xxl-5 {
          flex: 0 0 auto;
          max-width: 41.66666667%;
     }

     .col-xxl-6 {
          flex: 0 0 auto;
          max-width: 50%;
     }

     .col-xxl-7 {
          flex: 0 0 auto;
          max-width: 58.33333333%;
     }

     .col-xxl-8 {
          flex: 0 0 auto;
          max-width: 66.66666667%;
     }

     .col-xxl-9 {
          flex: 0 0 auto;
          max-width: 75%;
     }

     .col-xxl-10 {
          flex: 0 0 auto;
          max-width: 83.33333333%;
     }

     .col-xxl-11 {
          flex: 0 0 auto;
          max-width: 91.66666667%;
     }

     .col-xxl-12 {
          flex: 0 0 auto;
          max-width: 100%;
     }
}

.tippy-content {
     background-color: #19222b;
     border-radius: 5px;
}

.tippy-arrow {
     color: #19222b;
}

.full-page-overlay {
     height: 100%;
     width: 100%;
     position: fixed;
     z-index: 99999;
     top: 0;
     left: 0;
     background-color: $body-bg;
     overflow: hidden;
     display: flex;
     justify-content: center;
     align-items: center;
}

.columnCount {
     &__1 {
          column-count: 1;
     }

     &__2 {
          column-count: 2;
     }

     &__3 {
          column-count: 3;
     }
}

.input-border {
     border-radius: 1.25rem !important;
}

.mw-300 {
     max-width: 300px;
}

.mw-140 {
     min-width: 140px;
}

.btn svg.icon,
.btn i {
     height: 0.875rem;
     margin: 0.21875rem 0;
     width: 0.875rem;
}

.mw-174 {
     min-width: 174px;
}

.flex-1 {
     flex: 1;
}

body > .zIndex-sortable {
     z-index: 10001;
}

.ml-20 {
     margin-left: 20px;
}

.mr-20 {
     margin-right: 20px;
}

.m-0 {
     margin: 0;
}

.pt-1p {
     padding-top: 1px;
}

body > .zIndex-sortable {
     z-index: 10001;
}

.top-7px {
     top: 7px !important;
}

.top-13px {
     top: 13px !important;
}

.dropdown-scrollbar {
     max-height: 393px;
     overflow-y: auto;

     &::-webkit-scrollbar {
          width: 6px;
     }

     &::-webkit-scrollbar-track {
          background-color: transparent;
     }

     &::-webkit-scrollbar-thumb {
          background: #c8ced3;
          border-radius: 2px;
     }
}

.dropdown-icon-savedview {
     .tippy-box {
          color: #fff;
          background-color: #19222b;
          padding: 0.9375rem 1.0625rem;
          border-radius: 12px;
          border-radius: 12px;
          box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.13);
     }

     .tippy-content {
          font-size: 14px;
          color: #fff !important;
          font-weight: 400;
          text-align: left;
          padding: 0;
     }
}

.tooltip-goals {
     .tippy-box {
          color: #fff;
          background-color: #19222b !important;
          padding: 0.9375rem 1.0625rem;
          border-radius: 12px;
          border-radius: 12px;
          box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.13);
          width: 310px;
     }

     .tippy-content {
          font-size: 15px;
          color: #fff !important;
          font-weight: 400;
          text-align: left;
          padding: 0;
          max-width: unset;
     }
}

.saved-viewed-dropdown-item {
     &.created-saved-viewed {
          border-top: 1px solid #eeeeee;
          position: sticky;
          bottom: 0;
          background-color: white;
     }

     &:hover {
          text-decoration: none;
          color: #181b1e;
          background-color: #f0f3f5;
     }
}

.table-kpi-wrapper {
     .k-grid-footer {
          padding-right: 0;
     }
     .k-table-th.k-header {
          background: #8C9BA7;
     }
     .k-grid-norecords {
          text-align: left;
          &-template {
               border: unset;
               height: 2.8em;
               line-height: 2.8em;
               margin-left: 15px;
          }
     }
}

.select-setup-fields {
     [class$="-placeholder"] {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 80%;             
     }
}
