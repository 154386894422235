
$roboto: "Roboto", serif;
/* font-family body */
$font-family-base: $roboto;
$font-size-base: .875rem;

//Color system
$message-863 : #863f3f;


//Theme Colors
$body-colors: #18222B;
$secondary: #ced2d8;
$tertiary: #9FADC1;
$emphasis: #080A0C;
$border-colors: #E2E6E9;
$primary : #20A8D8;
$success: #4dbd74;
$danger: #F86B6B;
$warning: #ffc107;
$info: #63c2de;
$light: #f0f3f5;
$dark: #2f353a;
$white: #fff;
$black: #000;
$bg-selected: #2D3A44;

//Addition Colors

$indigo:  #6610F2 !default;
$pink:    #D63384 !default;
$orange:  #FD7E14 !default;
$green:   #22B15C !default;
$light-blue: #0098D3 !default;
$blue:    #0D6EFD !default;
$purple:  #6F42C1 !default;
$red:     #FF2F24 !default;
$yellow:  #FFC107 !default;
$teal:    #20C997 !default;
$cyan:    #0dcaf0 !default;

// Light Blue Colors
$light-blue-100: #E9F6FB !default;
$light-blue-200: #AFDFF0 !default;
$light-blue-300: #7BCAE7 !default;
$light-blue-400: #20A7D8 !default;
$light-blue-500: #0098D3 !default;
$light-blue-600: #008BC5 !default;
$light-blue-700: #0079B2 !default;
$light-blue-800: #00699F !default;
$light-blue-900: #004A7F !default;

//Grays
$gray-100: #FAFAFA;
$gray-200: #F1F3F5;
$gray-300: #D1D5DB;
$gray-400: #C8CED3;
$gray-500: #9FADC1;
$gray-600: #73818f;
$gray-700: #5c6873;
$gray-800: #1F2937;
$gray-900: #18222B;


// fusv-disable
$blue-100: #CFE2FF !default;
$blue-200: #9EC5FE !default;
$blue-300: #6EA8FE !default;
$blue-400: #3D8BFD !default;
$blue-500: $blue !default;
$blue-600: #0A58CA !default;
$blue-700: #084298 !default;
$blue-800: #052C65 !default;
$blue-900: #031633 !default;

$indigo-100: tint-color($indigo, 80%) !default;
$indigo-200: tint-color($indigo, 60%) !default;
$indigo-300: tint-color($indigo, 40%) !default;
$indigo-400: tint-color($indigo, 20%) !default;
$indigo-500: $indigo !default;
$indigo-600: shade-color($indigo, 20%) !default;
$indigo-700: shade-color($indigo, 40%) !default;
$indigo-800: shade-color($indigo, 60%) !default;
$indigo-900: shade-color($indigo, 80%) !default;

$purple-100: tint-color($purple, 80%) !default;
$purple-200: tint-color($purple, 60%) !default;
$purple-300: tint-color($purple, 40%) !default;
$purple-400: tint-color($purple, 20%) !default;
$purple-500: $purple !default;
$purple-600: shade-color($purple, 20%) !default;
$purple-700: shade-color($purple, 40%) !default;
$purple-800: shade-color($purple, 60%) !default;
$purple-900: shade-color($purple, 80%) !default;

$pink-100: tint-color($pink, 80%) !default;
$pink-200: tint-color($pink, 60%) !default;
$pink-300: tint-color($pink, 40%) !default;
$pink-400: tint-color($pink, 20%) !default;
$pink-500: $pink !default;
$pink-600: shade-color($pink, 20%) !default;
$pink-700: shade-color($pink, 40%) !default;
$pink-800: shade-color($pink, 60%) !default;
$pink-900: shade-color($pink, 80%) !default;

$red-100: #FFEAEE !default;
$red-200: #FE9695 !default;
$red-300: #F86B6B !default;
$red-400: #FF4343 !default;
$red-500: $red !default;
$red-600: #F82126 !default;
$red-700: #E60F21 !default;
$red-800: #D80019 !default;
$red-900: #C8000A !default;

$orange-100: tint-color($orange, 80%) !default;
$orange-200: tint-color($orange, 60%) !default;
$orange-300: tint-color($orange, 40%) !default;
$orange-400: tint-color($orange, 20%) !default;
$orange-500: $orange !default;
$orange-600: shade-color($orange, 20%) !default;
$orange-700: shade-color($orange, 40%) !default;
$orange-800: shade-color($orange, 60%) !default;
$orange-900: shade-color($orange, 80%) !default;

$yellow-100: #FFECB3 !default;
$yellow-200: #FFE082 !default;
$yellow-300: #FFD54F !default;
$yellow-400: #FFCA28 !default;
$yellow-500: $yellow !default;
$yellow-600: #FFB300 !default;
$yellow-700: #FFA000 !default;
$yellow-800: #FF8F00 !default;
$yellow-900: #FF6F00 !default;

$green-100: #C2E7CD !default;
$green-200: #9AD7AD !default;
$green-300: #70C98C !default;
$green-400: #4DBD74 !default;
$green-500: $green !default;
$green-600: #19A252 !default;
$green-700: #0A9046 !default;
$green-800: #007F3B !default;
$green-900: #005F27 !default;

$teal-100: tint-color($teal, 80%) !default;
$teal-200: tint-color($teal, 60%) !default;
$teal-300: tint-color($teal, 40%) !default;
$teal-400: tint-color($teal, 20%) !default;
$teal-500: $teal !default;
$teal-600: shade-color($teal, 20%) !default;
$teal-700: shade-color($teal, 40%) !default;
$teal-800: shade-color($teal, 60%) !default;
$teal-900: shade-color($teal, 80%) !default;

$cyan-100: tint-color($cyan, 80%) !default;
$cyan-200: tint-color($cyan, 60%) !default;
$cyan-300: tint-color($cyan, 40%) !default;
$cyan-400: tint-color($cyan, 20%) !default;
$cyan-500: $cyan !default;
$cyan-600: shade-color($cyan, 20%) !default;
$cyan-700: shade-color($cyan, 40%) !default;
$cyan-800: shade-color($cyan, 60%) !default;
$cyan-900: shade-color($cyan, 80%) !default;

$body-color:#2f353a !default;
$body-bg: $white;
$body-bg-modal: #f0f3f5;

$footer-bg: inherit;

$variable-prefix:             cui- !default; // Deprecated in v4.2.6 for the shorter `$prefix`
$prefix:                      $variable-prefix !default;

$uncolored : 'transparent' !default;

// scss-docs-start border-variables
$border-color: $border-colors !default;

// $badge-font-size: 0.6875em;

// $spinner-border-width: .25em;

//font-size
@function rem($pixels, $context: 16) {
    @if (unitless($pixels)) {
        $pixels: $pixels * 1px;
    }
    @if (unitless($context)) {
        $context: $context * 1px;
    }
    @return $pixels / $context * 1rem;
}

$font-b: 16;
$font-px: 16px;

$h1-font-size: rem(35);
$h2-font-size: rem(35);
$h4-font-size: rem(21);
$h5-font-size: rem(18);
$h6-font-size: rem(16);

$headings-line-height: 1.2 !default;

// headings variant
$heading-variants: (
  "h1": (
    'line-height': $headings-line-height,
  ),
  "h2": (
    'line-height': $headings-line-height,
  ),
  "h3": (
    'line-height': $headings-line-height,
  ),
  "h4": (
    'line-height': $headings-line-height,
  ),
  "h5": (
    'line-height': $headings-line-height,
  ),
  "h6": (
    'line-height': $headings-line-height,
  ),
) !default;

// Border
$border-radius-pill: rem(999) !default;
$border-radius: .25rem;

// Button variants
$btn-padding-y: rem(6);
$btn-padding-x: rem(12);
$btn-font-size: rem(14);
$btn-font-weight: 500;
$btn-line-height: 1.5;
$btn-border-width: rem(0);

$btn-padding-top: rem(6);
$btn-padding-bottom: rem(6);
$btn-padding-left: $btn-padding-x;
$btn-padding-right: $btn-padding-x;

$btn-padding-top-lg: rem(8);
$btn-padding-bottom-lg: rem(8);
$btn-padding-left-lg: rem(16);
$btn-padding-right-lg: rem(16);

$btn-border-radius: rem(52);
$btn-disabled-opacity: 0.5;
$btn-border-radius-dashboard: rem(8);

$form-switch-color: $white;
$form-switch-checked-color: $white;

$card-bg: $white;
$card-color: $black;
$card-cap-bg: $white;
$card-spacer-y: 1.25rem;
$card-spacer-x: 1.25rem;
$card-cap-padding-y: 0.75rem;
$card-border-color: #e4e7ea;

$dropdown-item-padding-y: rem(8);
$dropdown-item-padding-x: rem(20);
$dropdown-link-hover-bg: $light;

$modal-content-border-width: 0;

$header-border-width: 0;

$footer-bg: $white;
$footer-border-width: 0;
// focus shadow
$focus-ring-width:      rem(4);
$focus-ring-opacity:    1;
$focus-ring-blur:       0;
$focus-ring-x : 0;
$focus-ring-y : 0;

$button-variants: (
  "primary": (
    "bg": $primary,
    "border-color": $primary,
    "color": $white,
    "hover-bg": $light-blue-600,
    "hover-border-color": $light-blue-600,
    "hover-color": $white,
    "active-bg": $light-blue-800,
    "active-border-color": $light-blue-800,
    "active-color": $white,
    "disabled-bg": $primary,
    "disabled-border-color": $primary,
    "disabled-color": $white,
    "shadow": $light-blue-100,
  ),
  "secondary": (
    "bg": $secondary,
    "border-color": $secondary,
    "color": $white,
    "hover-bg": $gray-800,
    "hover-border-color": $gray-800,
    "hover-color": $white,
    "active-bg": $gray-900,
    "active-border-color": $gray-900,
    "active-color": $white,
    "disabled-bg": $secondary,
    "disabled-border-color": $secondary,
    "disabled-color": $white,
    "shadow": $gray-500,
  ),
  "success": (
    "bg": $success,
    "border-color": $success,
    "color": $white,
    "hover-bg": $green-800,
    "hover-border-color": $green-800,
    "hover-color": $white,
    "active-bg": $green-900,
    "active-border-color": $green-900,
    "active-color": $white,
    "disabled-bg": $green-400,
    "disabled-border-color": $green-400,
    "disabled-color": $white,
    "shadow": $green-200,
  ),
  "danger": (
    "bg": $danger,
    "border-color": $danger,
    "color": $white,
    "hover-bg": $red-400,
    "hover-border-color": $red-400,
    "hover-color": $white,
    "active-bg": $red-600,
    "active-border-color": $red-600,
    "active-color": $white,
    "disabled-bg": $danger,
    "disabled-border-color": $danger,
    "disabled-color": $white,
    "shadow": $red-200,
  ),
  "warning": (
    "bg": $warning,
    "border-color": $warning,
    "color": $white,
    "hover-bg": $yellow-800,
    "hover-border-color": $yellow-800,
    "hover-color": $white,
    "active-bg": $yellow-900,
    "active-border-color": $yellow-900,
    "active-color": $white,
    "disabled-bg": $warning,
    "disabled-border-color": $warning,
    "disabled-color": $white,
    "shadow": $yellow-200,
  ),
  "info": (
    "bg": $info,
    "border-color": $info,
    "color": $white,
    "hover-bg": $blue-600,
    "hover-border-color": $blue-600,
    "hover-color": $white,
    "active-bg": $blue-800,
    "active-border-color": $blue-800,
    "active-color": $white,
    "disabled-bg": $info,
    "disabled-border-color": $info,
    "disabled-color": $black,
    "shadow": $blue-200,
  ),
  "light": (
    "bg": $white,
    "border-color": $white,
    "color": $dark,
    "hover-bg": $gray-300,
    "hover-border-color": $gray-300,
    "hover-color": $dark,
    "active-bg": $gray-400,
    "active-border-color": $gray-400,
    "active-color": $dark,
    "disabled-bg": $gray-400,
    "disabled-border-color": $gray-400,
    "disabled-color": $white,
    "shadow": $gray-300,
  ),
  "dark": (
    "bg": $dark,
    "border-color": $dark,
    "color": $light,
    "hover-bg": $gray-800,
    "hover-border-color": $gray-800,
    "hover-color": $light,
    "active-bg": $gray-900,
    "active-border-color": $gray-900,
    "active-color": $light,
    "disabled-bg": $dark,
    "disabled-border-color": $dark,
    "disabled-color": $white,
    "shadow": $gray-700,
  ),
) !default;

$button-outline-ghost-variants: (
  "primary": (
    "bg": $primary,
    "border-color": $primary,
    "color": $primary,
    "hover-bg": $uncolored,
    "hover-border-color": $light-blue-600,
    "hover-color": $light-blue-600,
    "active-bg": $uncolored,
    "active-border-color": $light-blue-800,
    "active-color": $light-blue-800,
    "disabled-bg": $primary,
    "disabled-border-color": $primary,
    "disabled-color": $white,
    "shadow": $light-blue-200,
    "focus-bg": $white,
    "border-transparent": $uncolored,
  ),
  "secondary": (
    "bg": $secondary,
    "border-color": $secondary,
    "color": $secondary,
    "hover-bg": $uncolored,
    "hover-border-color": $gray-800,
    "hover-color": $gray-800,
    "active-bg": $uncolored,
    "active-border-color": $gray-900,
    "active-color": $gray-900,
    "disabled-bg": $secondary,
    "disabled-border-color": $secondary,
    "disabled-color": $white,
    "shadow": $gray-500,
    "focus-bg": $white,
    "border-transparent": $uncolored,
  ),
  "success": (
    "bg": $success,
    "border-color": $success,
    "color": $success,
    "hover-bg": $uncolored,
    "hover-border-color": $green-800,
    "hover-color": $green-800,
    "active-bg": $uncolored,
    "active-border-color": $green-900,
    "active-color": $green-900,
    "disabled-bg": $success,
    "disabled-border-color": $success,
    "disabled-color": $white,
    "shadow": $green-200,
    "focus-bg": $white,
    "border-transparent": $uncolored,
  ),
  "danger": (
    "bg": $danger,
    "border-color": $danger,
    "color": $danger,
    "hover-bg": $uncolored,
    "hover-border-color": $red-400,
    "hover-color": $red-400,
    "active-bg": $uncolored,
    "active-border-color": $red-600,
    "active-color": $red-600,
    "disabled-bg": $danger,
    "disabled-border-color": $danger,
    "disabled-color": $white,
    "shadow": $red-200,
    "focus-bg": $white,
    "border-transparent": $uncolored,
  ),
  "warning": (
    "bg": $warning,
    "border-color": $warning,
    "color": $warning,
    "hover-bg": $uncolored,
    "hover-border-color": $yellow-800,
    "hover-color": $yellow-800,
    "active-bg": $uncolored,
    "active-border-color": $yellow-900,
    "active-color": $yellow-900,
    "disabled-bg": $warning,
    "disabled-border-color": $warning,
    "disabled-color": $white,
    "shadow": $yellow-200,
    "focus-bg": $white,
    "border-transparent": $uncolored,
  ),
  "info": (
    "bg": $info,
    "border-color": $info,
    "color": $info,
    "hover-bg": $uncolored,
    "hover-border-color": $blue-600,
    "hover-color": $blue-600,
    "active-bg": $uncolored,
    "active-border-color": $blue-800,
    "active-color": $blue-800,
    "disabled-bg": $info,
    "disabled-border-color": $info,
    "disabled-color": $black,
    "shadow": $blue-200,
    "focus-bg": $white,
    "border-transparent": $uncolored,
  ),
  "light": (
    "bg": $light,
    "border-color": $light,
    "color": $light,
    "hover-bg": $uncolored,
    "hover-border-color": $gray-300,
    "hover-color": $gray-300,
    "active-bg": $uncolored,
    "active-border-color": $gray-400,
    "active-color": $gray-400,
    "disabled-bg": $gray-400,
    "disabled-border-color": $gray-400,
    "disabled-color": $white,
    "shadow": $gray-300,
    "focus-bg": $dark,
    "border-transparent": $uncolored,
  ),
  "dark": (
    "bg": $dark,
    "border-color": $dark,
    "color": $dark,
    "hover-bg": $uncolored,
    "hover-border-color": $gray-800,
    "hover-color": $light,
    "active-bg": $uncolored,
    "active-border-color": $gray-900,
    "active-color": $gray-900,
    "disabled-bg": $dark,
    "disabled-border-color": $dark,
    "disabled-color": $white,
    "shadow": $dark,
    "focus-bg": $white,
    "border-transparent": $uncolored,
  ),
) !default;

// Modal Variables
// $modal-content-bg: $body-bg-modal !default;
$modal-content-bg: $white !default;

$carousel-control-icon-width: 1.6rem !default;
$carousel-control-prev-icon-bg: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDEwIDEzIiBjbGFzcz0iYy1pY29uIGMtaWNvbi1jdXN0b20tc2l6ZSIgcm9sZT0iaW1nIj4NCiAgPGRlc2M+Q3JlYXRlZCB3aXRoIEx1bmFjeTwvZGVzYz4NCiAgICAgPGcgaWQ9Imljb24tdHlwZTMvY2hldnJvbi1ib3R0b20iIHRyYW5zZm9ybT0ibWF0cml4KDEuMTkyNDg4RS0wOCAxIC0xIDEuMTkyNDg4RS0wOCAxMCAwKSI+DQogICAgIDxwYXRoIGQ9Ik0xMyAyLjU1MTcyTDEwLjc3MjYgNC44Njc2NmUtMTZMNi41IDQuODk0NjRMMi4yMjc0MiAwTDAgMi41NTE3Mkw2LjUwMTY3IDEwTDguNzI5MSA3LjQ0ODI4TDguNzI3NDIgNy40NDYzNkwxMyAyLjU1MTcyTDEzIDIuNTUxNzJaIiBpZD0iU2hhcGUiIGZpbGw9IiM3MDgzOTMiIHN0cm9rZT0ibm9uZSI+PC9wYXRoPg0KICA8L2c+DQo8L3N2Zz4=") !default;
$carousel-control-next-icon-bg: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDEwIDEzIiBjbGFzcz0iYy1pY29uIGMtaWNvbi1jdXN0b20tc2l6ZSIgcm9sZT0iaW1nIiB2ZXJzaW9uPSIxLjEiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB0cmFuc2Zvcm09Im1hdHJpeCgtMSwwLDAsLTEsMCwwKSI+DQogIDxkZXNjPkNyZWF0ZWQgd2l0aCBMdW5hY3k8L2Rlc2M+DQogICAgIDxnIGlkPSJpY29uLXR5cGUzL2NoZXZyb24tYm90dG9tIiB0cmFuc2Zvcm09Im1hdHJpeCgxLjE5MjQ4OEUtMDggMSAtMSAxLjE5MjQ4OEUtMDggMTAgMCkiPg0KICAgICA8cGF0aCBkPSJNMTMgMi41NTE3MkwxMC43NzI2IDQuODY3NjZlLTE2TDYuNSA0Ljg5NDY0TDIuMjI3NDIgMEwwIDIuNTUxNzJMNi41MDE2NyAxMEw4LjcyOTEgNy40NDgyOEw4LjcyNzQyIDcuNDQ2MzZMMTMgMi41NTE3MkwxMyAyLjU1MTcyWiIgaWQ9IlNoYXBlIiBmaWxsPSIjNzA4MzkzIiBzdHJva2U9Im5vbmUiPjwvcGF0aD4NCiAgPC9nPg0KPC9zdmc+") !default;

$form-check-radio-checked-bg-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii00IC00IDggOCI+CiAgPGNpcmNsZSByPSIyIiBmaWxsPSJyZ2IoNTcsIDEwOSwgMjQ4KSIvPgo8L3N2Zz4=") !default;

// caption
$cation-base-font-size: rem(12);
$cation-base-line-height: rem(16);

// Button group
$ll-btn-group-border-color:            $border-colors;
$ll-btn-group-border-width:            rem(1);
$ll-btn-group-border-radius:           rem(8);
$ll-btn-group-bg:                      $light;
$ll-btn-group-bg-selected:             $white;
$ll-btn-group-color-selected:          $gray-800;
$ll-btn-group-bg-label:                $bg-selected;
$ll-btn-group-color-label:             $white;
$ll-btn-group-font-weight:             500;
$ll-btn-group-font-size:               rem(14);
$ll-btn-group-line-height:             rem(20);
$ll-btn-group-padding-top:             rem(5);
$ll-btn-group-padding-right:           rem(9);
$ll-btn-group-padding-bottom:          rem(5);
$ll-btn-group-padding-left:            rem(9);

// caret
// $caret-width:                 .3em !default;
// $caret-vertical-align:        $caret-width * .85 !default;
// $caret-spacing:               $caret-width * .85 !default;

// $dropdown-spacer:                   .125rem !default;

// $dropdown-header-padding-x:         $dropdown-item-padding-x !default;
// $dropdown-header-padding-y:         $dropdown-padding-y !default;
