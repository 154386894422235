.admin .table-data,
.admin .table-layout {
     margin-top: 24px;
     position: relative;

     .popup-publish-version {
          .modal-body {
               padding: 40px;
          }

          .popup-publish-version-description {
               margin-bottom: 25px;
          }
     }

     .headline {
          padding: 0 1.5rem;
          margin-bottom: 1.5rem;
     }
     .message-warning {
          margin-left: 1.5rem;
          margin-right: 1.5rem;
     }
     .css-table {
          display: table;
          margin-bottom: 1.2rem;
          width: 100%;
          .form-location {
               .tooltip-wrapper {
                    .block-tooltip {
                         left: calc(100% - 50px);
                    }
               }
          }
          .inherit-category {
               position: relative;
               .custom-checkbox {
                    &:hover {
                         + .block-tooltip {
                              display: block;
                              top: 5px;
                         }
                    }
                    .custom-control-label {
                         cursor: pointer;
                    }
                    .custom-control-input:disabled {
                         ~ .custom-control-label {
                              opacity: 0.5;
                              &::before {
                                   background-color: #ffffff;
                                   border-color: #8f9ba6;
                              }
                         }
                    }
                    .custom-control-input.inherit-disable:disabled:checked {
                         ~ .custom-control-label {
                              opacity: 0.5;
                              &::before {
                                   background-color: #20a8d8 !important;
                              }
                         }
                    }
               }
          }
          .inherit-category,
          .take-action {
               .block-tooltip {
                    left: auto;
                    right: calc(100% - 5px);
                    top: 10px;
                    min-width: auto;
                    height: max-content;
                    > svg {
                         left: auto;
                         right: -10px;
                         bottom: 0;
                         top: auto;
                         transform: rotate(180deg);
                         height: 12px !important;
                    }
                    &-text {
                         padding: 4px 10px;
                         border-radius: 5px;
                         border-bottom-right-radius: 0;
                         width: max-content;
                    }
               }
          }
     }
     .css-table {
          .take-action {
               width: 250px;
               div {
                    text-align: left;
               }
               div[class*='singleValue'] {
                    max-width: calc(100% - 25px);
               }
               .option-row div {
                    text-overflow: ellipsis;
                    overflow: hidden;
               }
               .no-options {
                    div {
                         text-align: center !important;
                    }
               }
          }
          .listener-name {
               width: 200px;
               &-parent {
                    cursor: pointer;
               }
          }
          .form-id {
               width: 100px;
          }
          .form-location {
               text-align: center;
               width: 180px;
          }
          .form-name {
               width: 240px;
               .form-control.is-invalid {
                    margin-bottom: 5px;
               }
          }
          .redirects {
               text-align: center;
               .tooltip-wrapper {
                    display: initial;
               }
          }
          .table-body-row {
               padding-left: 15px;
               .text-empty-form {
                    padding-top: 15px;
               }
               .tooltip-wrapper,
               .tooltip-wrapper > div {
                    padding-left: 0;
               }
               &.form-id .tooltip-wrapper,
               &.form-location .tooltip-wrapper {
                    position: relative;
                    span {
                         white-space: nowrap;
                         overflow: hidden;
                         text-overflow: ellipsis;
                         display: block;
                         cursor: pointer;
                    }
                    .block-tooltip {
                         width: -webkit-max-content;
                         width: -moz-max-content;
                         width: max-content;
                         max-width: 600px;
                         z-index: 999;
                         left: calc(100% - 40px);
                         &-text {
                              display: flex;
                              flex-direction: column;
                              grid-row-gap: 10px;
                              row-gap: 10px;
                         }
                    }
                    .tooltip-copy {
                         display: flex;
                         align-items: center;
                         grid-gap: 20px;
                         gap: 20px;
                         .tooltip-label {
                              word-break: break-all;
                         }
                         .tooltip-slug {
                              display: inline-flex;
                              img {
                                   filter: brightness(0) invert(1);
                              }
                         }
                    }
                    .icon-copy {
                         color: #20a8d8;
                         background-color: #fff;
                         border-radius: 12px;
                         padding: 2px 10px;
                         font-size: 12px;
                         font-weight: 600;
                         cursor: pointer;
                    }
               }
               &.form-location {
                    .tooltip-wrapper {
                         text-align: center;
                         > svg {
                              width: 16px;
                              height: 16px;
                         }
                    }
               }
          }
     }
     .css-table-row {
          .form-id {
               .tooltip-wrapper {
                    width: 100px;
               }
          }
          .inherit-category {
               text-align: center;
               width: 250px;
               font-size: 13px;
               color: #73818f;
               font-weight: 400;
               letter-spacing: 0.28px;
               input {
                    display: inline-block;
                    width: 14px;
                    height: 14px;
                    border-radius: 6px;
                    margin-right: 8px;
                    border-color: #c8ced3;
                    border: 1px solid #73818f !important;
                    cursor: pointer;
                    vertical-align: middle;
                    appearance: none;
                    -webkit-appearance: none;
                    outline: none;
                    &:checked {
                         background-image: url('/assets/icons/icon-checkbox.svg');
                         background-repeat: no-repeat;
                         background-position: center;
                         border: none;
                         opacity: 1;
                         background-color: #20a8d8;
                         border: none !important;
                    }
               }
          }
          .redirects-status,
          .reveal-people {
               width: 120px;
               text-align: center;
          }
     }
     .accordion-btn {
          background-color: transparent;
          padding: 0;
          margin-right: 12px;
          &:after {
               content: '';
               position: absolute;
               width: 7px;
               height: 7px;
               transform: translateY(-3px) rotate(-45deg);
               border: 2px solid #73818f;
               border-top: none;
               border-left: none;
               transition: transform 0.3s;
          }
          &.show {
               &:after {
                    transform: translateY(-6px) rotate(45deg);
               }
          }
     }
     .css-table-header,
     .css-table-row {
          & > div {
               display: table-cell;
               padding: 0.75rem;
          }
     }
     .block-tooltip {
          bottom: 17px;
          border: 1px solid none;
     }
     &.reporting-table-event {
          .block-tooltip {
               &.reporting-values-tooltip {
                    bottom: 42px;
               }
          }
     }
     .css-table-header {
          display: table-header-group;
          background-color: #18222b;
          color: #fff;
          & > div {
               border-bottom: none;
               font-weight: 400;
               padding-left: 1rem;
               vertical-align: middle;
               &:first-child {
                    border-top-left-radius: 12px;
               }
               &:last-child {
                    border-top-right-radius: 12px;
               }
          }
          .block-tooltip {
               .block-tooltip-text {
                    border-top-left-radius: 12px;
               }
          }
     }
     .css-table-body {
          display: table-row-group;
          .css-table-row {
               &:last-of-type {
                    > div {
                         border-bottom: solid 1px #f0f3f5;
                         &:first-child {
                              border-bottom-left-radius: 12px;
                         }
                         &:last-child {
                              border-bottom-right-radius: 12px;
                         }
                    }
               }
               > div {
                    &:first-child:not(:last-child) {
                         border-left: solid 1px #f0f3f5;
                    }
                    &:last-child:not(:first-child) {
                         border-right: solid 1px #f0f3f5;
                    }
               }
               .variable-name {
                    > div {
                         min-height: 45px;
                    }
               }
               .input-group {
                    border-bottom: none !important;
               }
               .event-custom-event-name,
               .variable-friendlyname {
                    width: 420px;
               }
               .feature-support {
                    &.checkout-step {
                         width: 170px;
                    }
               }
               .custom-control {
                    &-label {
                         &::before,
                         &::after {
                              top: 0;
                              box-shadow: none !important;
                         }
                    }
               }
               &.even {
                    background-color: #ffffff;
               }
               &.odd {
                    background-color: #f4f5f6;
               }
               .invalid-feedback {
                    display: none;
               }
               .is-invalid + .invalid-feedback {
                    display: block;
               }
          }
          .event-input-group {
               &.hide-arrows {
                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                         -webkit-appearance: none;
                         margin: 0;
                    }
                    input[type='number'] {
                         -moz-appearance: textfield;
                    }
               }
               input {
                    border: 1px solid #e2e6e9;
                    border-radius: 12px;
                    &[disabled] {
                         color: #9da7b1;
                         background-color: transparent;
                    }
                    &[readonly] {
                         background-color: #e4e7ea;
                    }
                    &.has-edit-btn {
                         padding-right: 37px;
                         &[disabled] {
                              padding-right: 37px;
                              &.is-invalid {
                                   // background-position: right 40px top 7px;
                                   padding-right: 60px;
                              }
                         }
                         &.cancel {
                              padding-right: 83px;
                              &[disabled] {
                                   padding-right: 83px;
                              }
                         }
                         &.is-invalid {
                              // background-position: right 85px top 7px;
                              padding-right: 92px;
                              background-position: right calc(0.375em + 0.0875rem) center;
                              & + .btn-edit {
                                   right: 26px;
                              }
                         }
                    }
                    &.is-invalid {
                         & + .btn-cancel-edit {
                              right: 30px;
                         }
                    }
               }
               .btn-edit {
                    background-color: transparent;
                    font-size: 80%;
                    color: #f96e6d;
                    border: 0;
                    padding: 0;
                    @include position-right-middle-param(12px);
                    &.disabled {
                         opacity: 1;
                         cursor: default;
                    }
               }
          }
     }
     .css-table-row {
          display: table-row;
          & > div {
               border-top: none;
               vertical-align: text-top;
               padding-left: 25px;
               span {
                    color: #73818f;
                    vertical-align: middle;
               }
               &.event-status {
                    span {
                         min-width: 49px;
                    }
               }
               &.bubble-message {
                    position: relative;
                    .session-length {
                         position: absolute;
                         right: 89%;
                         z-index: 9;
                         top: 60%;
                         background-color: #fff;
                         color: #fff;
                         width: 60vw;
                         max-width: 300px;
                         .session-length-container {
                              .input-container {
                                   display: flex;
                                   align-items: center;
                                   input {
                                        max-width: 100px;
                                        margin-right: 10px;
                                   }
                              }
                         }
                    }
                    .code-preview {
                         position: absolute;
                         right: 84%;
                         width: 370px;
                         z-index: 9;
                         top: 50%;
                         background-color: #19222b;
                         color: $white;
                         border-top-right-radius: unset;
                         &.across-domains {
                              width: 80vw;
                              max-width: 540px;
                         }
                         &.video-progresses {
                              background-color: #fff;
                              width: 380px;
                              p {
                                   color: #000;
                                   min-width: calc(100% - 40px);
                                   margin: 0;
                                   font-weight: 400;
                                   vertical-align: middle;
                              }
                              .feature-support-vidyard:not(:first-child) {
                                   padding-top: 15px;
                              }
                              &:after {
                                   content: '';
                                   display: block;
                                   height: 30px;
                                   width: 100%;
                                   position: absolute;
                                   z-index: 5;
                                   bottom: -30px;
                              }
                              .variable-input-group {
                                   .btn-edit {
                                        padding-left: 0;
                                   }
                              }
                              .remove-popup {
                                   .icon-close-popup,
                                   h2,
                                   .remove-popup-description {
                                        color: #000;
                                   }
                              }
                         }
                         & > .card-body {
                              & > svg {
                                   position: absolute;
                                   transform: scaleX(-1);
                                   right: -9px;
                                   cursor: pointer;
                                   &.icon-close-popup {
                                        color: #73818f;
                                        top: 5px;
                                        right: 5px;
                                   }
                              }
                         }
                         .video-progress-setting {
                              .headline {
                                   color: #000;
                                   padding: 0;
                                   font-size: 16px;
                                   font-weight: 500;
                                   line-height: 1.5em;
                                   margin-bottom: 5px;
                                   padding-right: 15px;
                                   &.fw-normal {
                                        font-weight: 400;
                                   }
                              }
                              .video-progress-content {
                                   .list-fields {
                                        overflow-y: auto;
                                        overflow-x: hidden;
                                        max-height: 350px;
                                   }
                              }
                         }
                         .divider {
                              display: block;
                              background-color: #c8ced3;
                              width: 100%;
                              height: 1px;
                              margin: 1em 0;
                         }
                         .session-length-container {
                              .input-container {
                                   display: flex;
                                   align-items: center;
                                   input {
                                        max-width: 100px;
                                        margin-right: 10px;
                                   }
                              }
                         }
                    }
                    & > svg {
                         cursor: pointer;
                         &:hover {
                              color: #73818f;
                         }
                    }
                    .arrow-preview {
                         position: absolute;
                         transform: rotateY(180deg);
                         right: -10px;
                         left: auto;
                    }
               }
               .invalid-feedback {
                    display: block;
               }
          }
          .variable-name {
               width: 30%;
               word-break: break-word;
          }
          .variable-friendlyname {
               width: 33%;
          }
          .variable-path {
               width: 15%;
          }
          &:nth-child(odd) {
               background-color: #f4f5f6;
          }
          &.inactive {
               input {
                    color: #abaeb2 !important;
               }
          }
          &.second-level-item {
               > div:first-child {
                    position: relative;
                    padding-left: 2.3rem;
                    &:before {
                         content: '';
                         width: 10px;
                         height: 100%;
                         background-color: #73818f;
                         position: absolute;
                         left: 0;
                         top: 0;
                    }
               }
          }
          &.third-level-item {
               > div:first-child {
                    position: relative;
                    padding-left: 2.9rem;
                    &:before {
                         content: '';
                         width: 10px;
                         height: 100%;
                         background-color: #73818f;
                         position: absolute;
                         left: 0;
                         top: 0;
                    }
                    &:after {
                         content: '';
                         width: 10px;
                         height: 100%;
                         background-color: #18222b;
                         position: absolute;
                         left: 10px;
                         top: 0;
                    }
               }
          }
          &.fourth-level-item {
               > div:first-child {
                    position: relative;
                    padding-left: 3.5rem;
                    &:before {
                         content: '';
                         width: 10px;
                         height: 100%;
                         background-color: #73818f;
                         position: absolute;
                         left: 0;
                         top: 0;
                    }
                    &:after {
                         content: '';
                         width: 20px;
                         height: 100%;
                         background: linear-gradient(to right, #18222b 50%, #73818f 50%);
                         position: absolute;
                         left: 10px;
                         top: 0;
                    }
               }
          }
          &.fifth-level-item {
               > div:first-child {
                    position: relative;
                    padding-left: 4.1rem;
                    &:before {
                         content: '';
                         width: 20px;
                         height: 100%;
                         background: linear-gradient(to right, #73818f 50%, #18222b 50%);
                         position: absolute;
                         left: 0;
                         top: 0;
                    }
                    &:after {
                         content: '';
                         width: 20px;
                         height: 100%;
                         background: linear-gradient(to right, #73818f 50%, #18222b 50%);
                         position: absolute;
                         left: 20px;
                         top: 0;
                    }
               }
          }
          &.sixth-level-item {
               > div:first-child {
                    position: relative;
                    padding-left: 4.7rem;
                    &:before {
                         content: '';
                         width: 30px;
                         height: 100%;
                         background: linear-gradient(to right, #73818f, #73818f 33.33%, #18222b 33.33%, #18222b 66.66%, #73818f 66.66%);
                         position: absolute;
                         left: 0;
                         top: 0;
                    }
                    &:after {
                         content: '';
                         width: 20px;
                         height: 100%;
                         background: linear-gradient(to right, #18222b 50%, #73818f 50%);
                         position: absolute;
                         left: 30px;
                         top: 0;
                    }
               }
          }
          &.seventh-level-item {
               > div:first-child {
                    position: relative;
                    padding-left: 5.3rem;
                    &:before {
                         content: '';
                         width: 30px;
                         height: 100%;
                         background: linear-gradient(to right, #73818f, #73818f 33.33%, #18222b 33.33%, #18222b 66.66%, #73818f 66.66%);
                         position: absolute;
                         left: 0;
                         top: 0;
                    }
                    &:after {
                         content: '';
                         width: 30px;
                         height: 100%;
                         background: linear-gradient(to right, #18222b, #18222b 33.33%, #73818f 33.33%, #73818f 66.66%, #18222b 66.66%);
                         position: absolute;
                         left: 30px;
                         top: 0;
                    }
               }
          }
          &.eighth-level-item {
               > div:first-child {
                    position: relative;
                    padding-left: 5.9rem;
                    &:before {
                         content: '';
                         width: 40px;
                         height: 100%;
                         background: linear-gradient(to right, #73818f, #73818f 25%, #18222b 25%, #18222b 50%, #73818f 50%, #73818f 75%, #18222b 75%);
                         position: absolute;
                         left: 0;
                         top: 0;
                    }
                    &:after {
                         content: '';
                         width: 30px;
                         height: 100%;
                         background: linear-gradient(to right, #73818f, #73818f 33.33%, #18222b 33.33%, #18222b 66.66%, #73818f 66.66%);
                         position: absolute;
                         left: 40px;
                         top: 0;
                    }
               }
          }
          &.ninth-level-item {
               > div:first-child {
                    position: relative;
                    padding-left: 6.5rem;
                    &:before {
                         content: '';
                         width: 40px;
                         height: 100%;
                         background: linear-gradient(to right, #73818f, #73818f 25%, #18222b 25%, #18222b 50%, #73818f 50%, #73818f 75%, #18222b 75%);
                         position: absolute;
                         left: 0;
                         top: 0;
                    }
                    &:after {
                         content: '';
                         width: 40px;
                         height: 100%;
                         background: linear-gradient(to right, #73818f, #73818f 25%, #18222b 25%, #18222b 50%, #73818f 50%, #73818f 75%, #18222b 75%);
                         position: absolute;
                         left: 40px;
                         top: 0;
                    }
               }
          }
          &.tenth-level-item {
               > div:first-child {
                    position: relative;
                    padding-left: 7.1rem;
                    &:before {
                         content: '';
                         width: 50px;
                         height: 100%;
                         background: linear-gradient(
                              to right,
                              #73818f,
                              #73818f 20%,
                              #18222b 20%,
                              #18222b 40%,
                              #73818f 40%,
                              #73818f 60%,
                              #18222b 60%,
                              #18222b 80%,
                              #73818f 80%
                         );
                         position: absolute;
                         left: 0;
                         top: 0;
                    }
                    &:after {
                         content: '';
                         width: 40px;
                         height: 100%;
                         background: linear-gradient(to right, #18222b, #18222b 25%, #73818f 25%, #73818f 50%, #18222b 50%, #18222b 75%, #73818f 75%);
                         position: absolute;
                         left: 50px;
                         top: 0;
                    }
               }
          }
     }
     .datalayer-variables {
          .btn-plus {
               box-shadow: none !important;
               background-color: transparent !important;
               i {
                    font-weight: 600;
               }
          }
     }
     &-loading {
          position: absolute;
          z-index: 1;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #ffffff70;
     }
}

.position-relative .table-responsive{
     position: relative;
}

.salesforce-log-table {
     position: relative;
     .position-relative {
          position: unset !important;
          .table-responsive{
               position: unset;
          }
     }

     .table-loading {
          position: absolute;
          top: 0;
          bottom: 0;
          z-index: 101;
          width: 100%;
          height: 100%;
          background: rgba(255, 255, 255, 0.4);
          display: flex;
          align-items: center;
          justify-content: center;
     }
     
     .table-log {
          overflow-x: auto;
          display: block;
     }

     .table-responsive, .table-log {
          scrollbar-width: thin;
          scrollbar-color: #888 #f1f1f1;

          & + div {
               top: 0 !important;
               bottom: 0 !important; 
          }

          &::-webkit-scrollbar {
               width: 8px;
               height: 8px;
          }

          &::-webkit-scrollbar-track {
               background: #f1f1f1;
               border-radius: 10px;
          }

          &::-webkit-scrollbar-thumb {
               background: #888;
               border-radius: 4px;
               border-radius: 10px;
          }

          &::-webkit-scrollbar-thumb:hover {
               background: #555;
          }

     }

     input[type="number"]::-webkit-inner-spin-button,
     input[type="number"]::-webkit-outer-spin-button {
     -webkit-appearance: none;
     margin: 0;
     }

     /* For Firefox */
     input[type="number"] {
     -moz-appearance: textfield;
     }
}

@media screen and (max-width: 1600px) {
     .personal-data .data-layer-events-layout .table-data .css-table-body .block-tooltip svg {
          left: 2px;
          top: 0;
     }
     .subscriber .table-data {
          .css-table-body {
               .css-table-row {
                    div {
                         padding-left: 0.75rem;
                    }
                    .feature-support {
                         &.checkout-step {
                              width: 300px;
                         }
                    }
               }
          }
     }
}
