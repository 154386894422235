.admin {
    .table-hover {
        tbody {
            tr {
                &:hover {
                    background-color: inherit !important;
                }
            }
        }
    }
}
